<template>
    <app-container>
        <el-row>
            <el-col :span="2" v-if="allowStatus" >
                <el-select v-model="statusVal" :placeholder="statusList[0].label" style="width: 100%">
                    <div v-for="(item,index) in statusList" :key="index" >
                        <el-option :label="item.label" :value="item.value"/>
                    </div>
                </el-select>
            </el-col>
            <el-col :span="2" v-if="allowPayStatus" >
                <el-select v-model="payStatusVal" :placeholder="payStatusList[0].label" style="width: 100%">
                    <div v-for="(item,index) in payStatusList" :key="index" >
                        <el-option :label="item.label" :value="item.value"/>
                    </div>
                </el-select>
            </el-col>
            <el-col :span="2" v-if="DatePickSelect">
                <el-select v-model="datePickStr" :placeholder="DatePickList[0].label" style="width: 100%">
                    <div v-for="(item,index) in DatePickList" :key="index" >
                        <el-option :label="item.label" :value="item.value"/>
                    </div>
                </el-select>
            </el-col>
            <el-col :span="5" v-if="DatePickSelect">
                <el-date-picker
                    style="width: 100%"
                    v-model="dateRange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="下单开始日期"
                    end-placeholder="下单结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-col>
            <el-col :span="4">
                <el-input id="input" type="text" v-model="filterStr" placeholder="关键词模糊搜索" @change="onRefresh" clearable />
            </el-col>
            <el-col :span="5">
                <el-button-group v-if="AddBtn">
                    <el-button type="primary" @click="onRefresh" icon="el-icon-search">查询</el-button>
                    <el-button type="primary" @click="onCreate" icon="el-icon-circle-plus-outline">新增</el-button>
                </el-button-group>
                <el-button v-else type="primary" @click="onRefresh" icon="el-icon-search">查询</el-button>
            </el-col>
        </el-row>
        <el-table :data="listData.Items" ref="table" @row-dblclick="onDoubleSelected" border stripe v-loading="loading" :height="tableHeight" style="width: 100%;" >
            <!--            <el-table-column fixed type="index" width="50"/>-->
            <!--显示的字段-->
            <div v-for="(item, index) in FieldList" :key="index">
                <el-table-column v-if="item.type == 'bool'" :prop="item.prop" :label="item.label" :width="item.width" :formatter="formatBoolean" show-overflow-tooltip/>
                <el-table-column v-else-if="item.type == 'image'" :prop="item.prop" :label="item.label" :width="item.width" show-overflow-tooltip>
                    <template :width="item.width" slot-scope="scope">
                        <img style="width:60px;height:60px;border:none;" :src="scope.row[item.prop]">
                    </template>
                </el-table-column>
                <el-table-column :fixed="index == 0 ? true : item.fixed" v-else :prop="item.prop" :label="item.label" :width="item.width" show-overflow-tooltip/>
            </div>
            <el-table-column v-if="Operation.length > 0" fixed="right" label="操作" :width="100">
                <template slot-scope="scope">
                    <div v-for="(item,index) in Operation" :key="index">
                        <el-button v-if="item == 'view'" type="text" size="small" @click="onEdit(scope.row)">查看</el-button>
                        <el-button v-if="item == 'delete'" type="text" size="small" @click="onDelete(scope.row)">删除</el-button>
                        <el-button v-if="item == 'disable'" type="text" size="small" @click="onDisable(scope.row)">禁用</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="currentSize"
            :total="listData.Total"
            :page-sizes="[20, 50, 100, 200, 500]"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>

        <!--新增表单-->
        <el-dialog :visible.sync="addVisible">
                <el-form label-position="right" label-width="100px">
                    <el-row>
                        <b-s-y-form-item
                            v-for="(item, index) in DynamicForm"
                            v-model="billModel[item.value]"
                            :modelField="item.value"
                            :type="item.type"
                            :key="index"
                            :col="item.col"
                            :required="item.required"
                            :label="item.label"
                            :labelWidth="item.labelWidth"
                            :placeholder="item.placeholder"
                            :readonly="item.readonly"
                            :rules="item.rules"
                            :field="item.field"
                            :searchObj="item.searchObj"
                            @onSuccessChange="onSuccessChange"
                        />
                    </el-row>
                </el-form>
            <div style="height: 30px"/>
            <div>
                <el-button type="primary" plain @click="onDyFormSave">确认保存</el-button>
                <el-button type="success" plain @click="onDyFormCancel">取消保存</el-button>
            </div>
        </el-dialog>
    </app-container>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from "moment";
import BSYFormItem from '@/components/bsy-ui/bsy-form-item'

export default {
    name: 'temp-list',
    components: {BSYFormItem},
    computed: {
        ...mapGetters(['tag']),
        tableHeight() {
            return this.winHeight - 170 + 'px'
        }
    },
    watch: {
        listData() {
            this.doLayout()
        }
    },
    props:{
        //是否启用状态查询
        allowStatus: {
            type: Boolean,
            default: false
        },
        //状态列表
        statusList:{
            type: Array,
            default: () => [
                { label: "全部状态", value: "1==1" },
                { label: "待提交", value: 'Status=="A"' },
                { label: "已提交", value: 'Status=="B"' },
                { label: "已受理", value: 'Status=="C"' },
                { label: "已驳回", value: 'Status=="D"' },
                { label: "已告知", value: 'Status=="E"' },
                { label: "已结案", value: 'Status=="F"' },
                { label: "已关闭", value: 'Status=="G"' },
            ]
        },
        //是否启用付款状态查询
        allowPayStatus: {
            type: Boolean,
            default: false
        },
        //状态列表
        payStatusList:{
            type: Array,
            default: () => [
                { label: "全部付款状态", value: "1==1" },
                { label: "未付款", value: 'Status=="A"' },
                { label: "部分付款", value: 'Status=="B"' },
                { label: "全额付款", value: 'Status=="C"' },
            ]
        },
        //是否显示新增按钮
        AddBtn: {
            type: Boolean,
            default: false
        },
        //是否启用状态查询
        DatePickSelect: {
            type: Boolean,
            default: false
        },
        //时间过滤查询列表
        DatePickList:{
            type: Array,
            default: () => [
                { label: '创建日期', value: 'CreatedOn' },
            ]
        },
        //接口实体类名
        Entity:{
            type: String,
            default: ''
        },
        //接口实体方法
        Method:{
            type: String,
            default: 'GetList'
        },
        //详情的路径
        DetailPath:{
            type: String,
            default: ''
        },
        //字段
        FieldList:{
            type: Array,
            default: () => [
                { label: '编码', prop: 'Number', width: '100', fixed: true },
                { label: '名称', prop: 'Name' }
            ]
        },
        //操作
        Operation:{
            type: Array,
            default: () => []
        },
        //是否允许双击
        DoubleClick:{
            type: Boolean,
            default: true
        },

        DynamicForm: {
            type: Array,
            default: () => [
                {
                    label: '名称',
                    value: 'Name',
                    type: 'text',
                    placeholder: '',
                    readonly: false,
                    rules: []
                }]
        }
    },
    data() {
        return {
            winHeight: 0,
            loading: true,
            filterStr: '', //快捷查询的文本框
            statusVal: '1==1', //单据状态快捷查询下拉选择值
            payStatusVal: '1==1', //付款状态快捷查询下拉选择值
            datePickStr: 'CreatedOn',//日期快捷查询的下拉选择框
            currentPage: 1,
            currentSize: 20,
            listData: [{Total: 0,Items: []}],

            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const today = new Date();
                        picker.$emit('pick', [today, today]);
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', [date, date]);
                    }
                },{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                },{
                    text: '最近六个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            dateRange: [],

            addVisible: false,
            fileUrl: [],
            billModel:{},
        }
    },
    mounted() {
        //获取窗体的高度
        this.winHeight = window.innerHeight
        let that = this
        window.onresize = () => {
            return (() => {
                that.winHeight = window.innerHeight
            })()
        }

        //最近六个月
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
        this.dateRange = [start, end]

        this.onRefresh()
    },
    activated() {
        this.doLayout()
    },
    methods: {
        onDyFormSave(){
            // if(this.imageList.length > 0)
            //     this.billModel.fileUrl = ""

            this.$API.Save(this.Entity, this.billModel, resp => {
                this.$Util.MsgBox(resp.Message, 1)

                this.addVisible = false

                if(this.fileUrl.length > 0)
                    this.onUploadImg()
                else
                    this.onRefresh()
            })
        },

        async onUploadImg(){
            let formData = new FormData()
            formData.append(this.entity + 'ID', this.billID)
            formData.append('GroupID', this.billModel.GroupID)

            //遍历图片
            for (const pic of this.imageList){
                formData.append('File', pic.raw)
            }

            await this.$API.SyncPostService("File/Upload" + this.entity + "/PostForm", formData)

            this.onRefresh()
        },

        onDyFormCancel(){
            this.imageList = []
            this.addVisible = false
        },

        // 上传轮播图
        onSuccessChange(file){
            this.imageList = file
        },

        doLayout() {
            this.$nextTick(() => {
                this.$refs.table.doLayout()
            })
        },
        formatBoolean: function (row, column, cellValue) {
            let ret = ''
            if (cellValue) {
                ret = "是"
            } else {
                ret = "否"
            }
            return ret;
        },
        handleSizeChange(val) {
            this.currentSize = val
            this.onRefresh()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.onRefresh()
        },
        // 查询按钮 - 获取接口数据
        onRefresh() {
            let start = moment(this.dateRange[0]).format('YYYY-MM-DD')
            let end = moment(this.dateRange[1]).add(1, 'days').format('YYYY-MM-DD')

            let filter = ""

            //是否启用日期过滤
            if(this.DatePickSelect){
                filter = this.datePickStr + " >= \"" + start + "\" && " + this.datePickStr + " <\"" + end + "\""
            }

            //是否启用单据状态过滤
            if(this.allowStatus){
                filter += ' && ' + this.statusVal
            }

            //是否启用付款状态过滤
            if(this.allowPayStatus){
                filter += ' && ' + this.payStatusVal
            }

            let params = {
                Page: this.currentPage,
                Size: this.currentSize,
                Filter: filter,
                Keyword:  this.filterStr,
                Sort: "CreatedOn desc"
            }
            this.loading = true
            if(this.Method === "Service"){
                this.$API.PostService(this.Entity + '/GetList', params, resp => {
                    if(resp.Status === 1){
                        this.listData = resp.Data
                        this.loading = false
                    }
                })
            }
            else {
                this.$API.GetList(this.Entity, params, resp => {
                    if(resp.Status === 1){
                        this.listData = resp.Data
                        this.loading = false
                    }
                })
            }
        },

        //新增按钮
        onCreate() {
            this.$API.Create(this.Entity, resp => {
                this.billModel = resp.Data
                this.addVisible = true
            })
            // this.$Util.NavigateTo(this.DetailPath, { id: 0 })
        },

        //双击行
        onDoubleSelected(data){
            if(this.DoubleClick){
                this.billModel = data
                this.addVisible = true
            }
        },

        //编辑
        onEdit(data){
            this.$Util.NavigateTo(this.DetailPath, { id: data.ID })
        },

        //删除
        onDelete(data){
            this.$confirm(this.$Util.IsNull(data.Number) ? '是否删除' : '是否删除' + data.Number, '提示' , {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$API.Remove(this.Entity, data.ID, () => {
                    this.onRefresh()
                })
            })
        },

        //禁用
        onDisable(data){
            this.$confirm(this.$Util.IsNull(data.Number) ? '是否禁用' : '是否禁用' + data.Name, '提示' , {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$API.Disable(this.Entity, data.ID, () => {
                    this.onRefresh()
                })
            })
        }
    }
}
</script>

<style>
.el-range-editor--small.el-input__inner {
    /*height: 28px;*/
}
</style>
