<template>
    <temp-list :Entity="entity" :Method="method" :FieldList="fieldList" :Operation="operation"
                     :double-click="true" :add-btn="true"/>
</template>

<script>
import TempList from "../../components/template/temp-list";
export default {
    //文章类型
    name: 'articleGroup-list',
    components: {TempList},
    data() {
        return {
            entity: 'ArticleGroup', //接口实体类名,
            method: 'GetList', //接口实体方法
            fieldList: [
                { label: '编码', prop: 'ID' },
                { label: '名称', prop: 'Name' },
            ],
            operation: []
        }
    },
    mounted() {

    },

    methods: {

    }
}
</script>

<style></style>
