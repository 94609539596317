<template>
    <temp-bill-list :Entity="entity" :Method="method" :FieldList="fieldList" :DetailPath="detailPath" :Operation="operation"
                    :add-btn="addBtn"/>
</template>

<script>

import TempBillList from "../../components/template/temp-bill-list";
export default {
    //信用金融-贷款申请单
    name: 'loanRequest-list',
    components: {TempBillList},
    data() {
        return {
            entity: 'LoanRequestBill', //接口实体类名,
            method: 'GetList', //接口实体方法
            detailPath: '/loanRequest-detail', //详情的路径
            fieldList: [
                { label: '申请单号', prop: 'Number' },
                { label: '业务日期', prop: 'BizDateText' },
                { label: '申请企业', prop: 'Enterprise.Name' },
                { label: '期望贷款额度', prop: 'LoanAmount', },
                { label: '期望到账时间', prop: 'ExpectedDate' },
                { label: '期望使用时长', prop: 'ExpectedPeriod' },
            ],
            addBtn: this.$Util.GetStore("CurrentClientID") === "Enterprise",
            operation: ['view'],
        }
    },
    mounted() {

    },

    methods: {

    }
}
</script>

<style></style>
