<template>
    <temp-basic-list :Entity="entity" :Method="method" :FieldList="fieldList" :Operation="operation"
                     :DoubleClick="false" :add-btn="false" />
</template>

<script>

import TempBasicList from "../../components/template/temp-basic-list";
export default {
    name: 'mediatorUser-list',
    components: {TempBasicList},
    data() {
        return {
            entity: 'MediatorUser', //接口实体类名,
            method: 'GetList', //接口实体方法
            fieldList: [
                { label: '编码', prop: 'Number', width: '200', fixed: true },
                { label: '名称', prop: 'Name', width: '200', fixed: true },
                { label: '是否管理员', prop: 'IsAdmin' },
            ],
            operation: []
        }
    },
    mounted() {

    },

    methods: {

    }
}
</script>

<style></style>
