<template>
    <el-dialog :modal-append-to-body="false" :visible.sync="thisVisible" v-if="thisVisible" :with-header="false" :show-close="true" width="98%" top="3vh" :title="title" center>
        <el-container class="el-dialog__body">
            <el-aside width="400px" v-show="isGroup">
                <ob-tree groupType="Product" apiEntry="Group" title="" :buttonVisible="false" ref="obTree" @onClickNode="onClickTreeNode" @refresh="onGetGroup" />
            </el-aside>
            <el-main>
                <el-row>
                    <el-col :span="7">
                        <el-input type="text" size="mini" v-model="filterStr" placeholder="编码、名称" @change="onQueryTable" clearable>
                            <template slot="prepend">快捷查询</template>
                        </el-input>
                    </el-col>
                    <el-col :span="17">
                        <ob-buttons :buttons="buttonsGroup" :parentVue="thisVue"/>
                    </el-col>
                </el-row>
                <ob-grid ref="obGrid" :columns="columns" :multi-select="true" :filter-row="true" :paging="true" selectMode="single"
                         @rowClick="onRowClick" @rowDblClick="onRowDblClick" @LoadData="onQueryTable"/>
            </el-main>
        </el-container>
    </el-dialog>
</template>

<script>
export default {
    name: 'ObBaseModal',
    props: {
        // buttonsGroup: {
        //     type: Array,
        //     default: null
        // }
    },
    data() {
        return {
            thisVue: this,

            isGroup: false, //是否需要显示分组模块
            thisVisible: false,//是否显示
            title: '', //标题
            field: '', //字段名
            indexStr: '',//如果是单体的调用，需要记录当前单体的index用来回调
            entity: '',//
            method: '',//
            filterStr: '',//过滤查询
            checkData: {},//选择的基础资料数据

            columns: [
                {
                    caption: '编码',
                    dataField: 'Number',
                    dataType: 'string',
                    fixed: true,
                    fixedPosition: 'left',
                    alignment: 'left',
                    allowSorting: true,
                    allowFiltering: true
                },
                {
                    caption: '名称',
                    dataField: 'Name',
                    dataType: 'string',
                    fixed: true,
                    fixedPosition: 'left',
                    alignment: 'left',
                    allowSorting: true,
                    allowFiltering: true
                }
            ],

            buttonsGroup: [
                { label: '查询', event: 'onQueryTable', icon: '', disabled: false },
                { label: '新增', event: 'onCreate', icon: '', disabled: true },
                { label: '确定', event: 'onConfirm', icon: '', disabled: false },
                { label: '取消', event: 'onCancel', icon: '', disabled: false },
            ]
        }
    },
    mounted() {

    },
    methods: {
        setVisible(e, entity) {
            this.thisVisible = e
            this.entity = entity

            setTimeout(() => {
                this.onGetGroup()
                this.onQueryTable()
            }, 500)
        },

        ShowBaseModal(title, field, entity, method, indexStr) {
            console.log("选择基础资料")
            this.isGroup = false //是否显示分组模块
            this.thisVisible = true //是否显示

            this.title = '基础资料选择 - ' + title //标题
            this.field = field //需要赋值的字段名
            this.indexStr = indexStr //如果是单体的调用，需要记录当前单体的index
            this.entity = entity
            this.method = method
            this.filterStr = ''
            this.checkData = {}

            //加延时加载，防止操作过快对应的组件对象未初始化完成
            setTimeout(() => {
                // this.onGetGroup()
                this.onQueryTable()
            }, 500)
        },


        // 配件--基础资料
        ShowBaseModalParts(title, field, entity, method, indexStr) {
            this.isGroup = false //是否显示分组模块
            this.thisVisible = true //是否显示
            this.title = '基础资料选择 - ' + title //标题
            this.field = field //需要赋值的字段名
            this.indexStr = indexStr //如果是单体的调用，需要记录当前单体的index
            this.entity = entity
            this.method = method
            this.filterStr = ''
            this.checkData = {}

            //加延时加载，防止操作过快对应的组件对象未初始化完成
            setTimeout(() => {
                // this.onGetGroup()
                this.onQueryTableParts()
            }, 500)
        },
        //配件--基础资料模板 - 配件查询表格接口
        onQueryTableParts() {
            this.$refs.obGrid.SetBaseDataParts(this.entity, this.method, this.filterStr)
        },



        //基础资料模板 - 查询分组接口
        onGetGroup(){
            let param = {
                pEntityType: this.entity
            }
            this.$API.PostMethod('Group', 'GetTree', param, (resp) => {
                if(resp.Data.length > 0){
                    this.isGroup = true
                    this.$refs.obTree.binding(resp.Data)
                }else{
                    this.isGroup = false
                }
            })
        },

        //基础资料模板 - 查询表格接口
        onQueryTable() {
            console.log(" this.title" ,  this.title )
            if(this.title === '基础资料选择 - 配件'){
                this.$refs.obGrid.SetBaseDataParts(this.entity, this.method, this.filterStr)
            }else if(this.title === '基础资料选择 - 商品'){
                this.$refs.obGrid.SetBaseDataMateri(this.entity, this.method, this.filterStr)
            }else{
                this.$refs.obGrid.SetBaseData(this.entity, this.method, this.filterStr)
            }

        },

        onClickTreeNode(data){
            let parentId = data.ID
            let filter = 'GroupID=' + parentId
            this.$refs.obGrid.SetData(this.entity, filter, '', '', 'GetListEx')
        },

        //单击行获取到的数据
        onRowClick(data){
            this.checkData = data
        },

        //双击表格行获取行数据
        onRowDblClick(data){
            this.checkData = data
            this.onConfirm()
        },

        // onCheckSelData(){
        //     //获取勾选的行数组
        //    let checkArr = this.$refs.obGrid.selRowsData
        //
        //     switch (checkArr.length) {
        //         case 0:
        //             this.$Util.MsgBox('请勾选需要的基础资料后确认！', 0)
        //             break
        //
        //         case 1:
        //             this.checkData = checkArr[0]
        //             this.onConfirm()
        //             break
        //
        //         default:
        //             // 未启用//判断是否单据体的基础资料选择(indexStr有值则是单据体的基础资料选择)
        //             // if(this.$Util.IsNull(this.indexStr)){
        //                 this.$Util.MsgBox('基础资料选择不允许多选！', 0)
        //                 // return
        //             // }
        //             // this.checkData = checkArr[0]
        //             // this.onConfirm()
        //             break;
        //     }
        // },

        //新增
        onCreate(){

        },

        //确定
        onConfirm(){
            if(this.$Util.IsNull(this.checkData)){
                this.$Util.MsgBox('请先点击选择数据行！', 0)
                return
            }

            this.thisVisible = false
            this.$emit('ReturnSelected', this.field, this.indexStr, this.checkData)
        },

        //取消
        onCancel(){
            this.thisVisible = false
        }
    }
}
</script>

<style lang="scss">
    .el-dialog__body{
        padding: 0px 25px 25px 30px!important;
    }
</style>
