<template>
    <div class="page-request-detail">
        <el-form :model="billModel" :rules="rules" ref="ruleForm" label-position="right" label-width="100px" :disabled="disabled">
            <el-card class="bsy-bill-card">
                <div slot="header">
                    <span>基本信息</span>
                    <!--                    <el-button type="primary" style="padding: 3px;margin-left: 20px" @click="onShowProgress" :disabled="false">进度查询</el-button>-->
                    <!--                    <el-button type="primary" style="padding: 3px;margin-left: 20px" @click="onRelevantBill" :disabled="false">查看受理单</el-button>-->
                </div>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="申请单号">
                            <el-input readonly v-model="billModel.Number" placeholder="保存后自动生成" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="业务日期" prop="BizDateText">
                            <el-input readonly v-model="billModel.BizDateText" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="申请企业">
                            <el-input readonly v-model="billModel.Enterprise.Name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="申请电话">
                            <el-input readonly v-model="billModel.EnterpriseUser.Phone" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="期望贷款额度">
                            <el-input v-model="billModel.LoanAmount" type="number" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="期望到账时间" prop="ExpectedDate">
                            <el-date-picker v-model="billModel.ExpectedDate" type="date" placeholder="请选择期望到账时间" style="width: 100%">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="期望使用时长">
                            <el-input v-model="billModel.ExpectedPeriod" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="申请备注">
                            <el-input v-model="billModel.Remark" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

            <el-card class="bsy-bill-card">
                <div slot="header">
                    <span>其他信息</span>
                </div>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="创建时间">
                            <el-input readonly v-model="billModel.CreatedOn" placeholder="保存后自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="billModel.CreatedBy" placeholder="保存后自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="修改时间">
                            <el-input readonly v-model="billModel.ModifiedOn" placeholder="保存后自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="修改人">
                            <el-input readonly v-model="billModel.ModifiedBy" placeholder="保存后自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
        </el-form>
        <bsy-buttons ref="BSYButtons" :buttons="buttons" :parentVue="thisVue" />
        <bsy-progress ref="BSYProgress" />

        <el-dialog title="驳回" :visible.sync="showReason" width="30%" center>
            <el-input v-model="ReasonStr" type="textarea" :rows="3" placeholder="请输入驳回说明"/>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showReason = false">取 消</el-button>
                <el-button type="primary" @click="onConfirmReject">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BsyButtons from "../../components/bsy-ui/bsy-buttons";
import BsyProgress from "../../components/bsy-ui/bsy-progress";

export default {
    //贷款申请单
    name: 'loanRequest-detail',
    components: {BsyProgress, BsyButtons},
    computed: {
        ...mapGetters(['USER', 'collapsed']),
    },
    data() {
        var checkOweAmount = (rule, value, callback) => {
            // if (Number.isInteger(Number(value)) && Number(value) > 0) {
            if (Number(value) > 0) {
                callback();
            } else {
                callback(new Error('请输入正确的应付金额'));
            }
        };
        var check2 = (rule, value, callback) => {
            if (Number(value) > 0) {
                callback();
            } else {
                callback(new Error('请选择被申请方的企业类型'));
            }
        };
        return {
            thisVue: this,
            buttons: [],

            currentUser: {},
            // 实体类名
            entity: 'LoanRequestBill',

            disabled: false,
            billID: 0, //单据表头ID
            billModel: {},
            rules: {
                OweAmount: [
                    { validator: checkOweAmount, trigger: 'blur' },
                ],
                // OweStartDate:[
                //     { type: 'date', required: true, message: '请选择逾期起始', trigger: 'change' }
                // ],
                ResponseEnterpriseTypeID:[
                    { validator: check2, trigger: 'blur' },
                ],
                ResponseEnterprise: [
                    { required: true, message: '请输入被申请方企业名称', trigger: 'blur' },
                ],
                ResponseLicense: [
                    { required: true, message: '请输入被申请方营业执照', trigger: 'blur' },
                ],
                ResponsePhone: [
                    { required: true, message: '请输入被申请方公司电话', trigger: 'blur' },
                ],
            },

            EnterpriseList: [],
            EnterpriseTypeList: [],

            showReason: false,
            ReasonStr: ''
        }
    },
    mounted() {
        this.onInit()

        this.billID = Number(this.$route.query.id)
        if (this.billID === 0) {
            this.onCreate()
        }else{
            this.onGetModel()
        }
    },
    methods: {
        // 初始化
        onInit(){
            // // 企业类型
            // this.$API.GetList('EnterpriseType', {}, (resp) => {
            //     this.EnterpriseTypeList = resp.Data.Items
            // })
            // // 企业类型
            // this.$API.GetList('Enterprise', {}, (resp) => {
            //     this.EnterpriseList = resp.Data.Items
            // })
        },

        onCreate(){
            this.$API.Create(this.entity, (resp) => {
                this.billModel = resp.Data

                this.onSetIdentity()
            })
        },

        onGetModel() {
            this.$API.GetModel(this.entity, this.billID, (resp) => {
                this.billModel = resp.Data

                this.onSetIdentity()
            })
        },

        onSetIdentity(){
            let status = this.billModel.Status
            let currentClientID = this.$Util.GetStore("CurrentClientID")
            switch (currentClientID){
                //管理员
                case "Admin":
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    this.disabled = (!(status === "B"))
                    this.buttons = [
                        { label: '保存', event: 'onSave', icon: 'el-icon-check', disabled: this.disabled },
                    ]
                    break

                //企业机构
                case "Enterprise":
                    this.disabled = (!(status === "A" || status === "D"))
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    this.buttons = [
                        { label: '保存', event: 'onSave', icon: 'el-icon-check', disabled: this.disabled },
                    ]
                    break

                //调解机构
                case "Mediator":
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    this.disabled = (status !== "B")
                    this.buttons = [
                        { label: '受理', event: 'onAudit', icon: 'el-icon-refresh-left', disabled: this.disabled },
                        { label: '驳回', event: 'onReject', icon: 'el-icon-finished', disabled: this.disabled },
                    ]
                    break

                //社会组织
                case "Community":
                    this.disabled = (!(status === "A" || status === "D"))
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    this.buttons = [
                        { label: '保存', event: 'onSave', icon: 'el-icon-check', disabled: this.disabled },
                        { label: '提交', event: 'onSubmit', icon: 'el-icon-finished', disabled: this.disabled }
                    ]
                    break
            }
        },

        //企业
        onSelEnterprise(id){
            let array = this.EnterpriseList.filter(item => {
                if(item.ID === id) return true
            })
            this.billModel.EnterpriseID = array[0].ID
        },

        onShowProgress(){
            if(this.billID == 0){
                this.$Util.MsgBox("请先保存单据！", 0)
                return
            }
            this.$refs.BSYProgress.onShowDialog(this.billID)
        },

        onRelevantBill(){
            //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
            if(this.billModel.Status === "A" || this.billModel.Status === "B" || this.billModel.Status === "D"){
                this.$Util.MsgBox(this.billModel.StatusName + "不存在受理单！", 0)
                return
            }
            this.$Util.NavigateTo("/response-detail", { id: this.billModel.ResponseBillID })
        },

        // 保存
        onSave() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    if(this.billModel.Status === "A" || this.billModel.Status === "D"){
                        this.$API.Save(this.entity, this.billModel, resp => {
                            this.$Util.MsgBox(resp.Message, 1)
                            this.billID = resp.Data.ID

                            this.onGetModel()
                        })
                    }else{
                        this.$Util.MsgBox("【待提交】或【已驳回】状态才允许保存操作！", -1)
                    }
                } else {
                    return false;
                }
            })
        },

        // 提交
        onSubmit() {
            if(this.billID === 0) return

            //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
            if(this.billModel.Status === "A" || this.billModel.Status === "D"){
                this.$API.PostService('RequestBill/Submit', { BillID: this.billID }, resp => {
                    this.$Util.MsgBox(resp.Message, 1)
                    this.onGetModel()
                })
            }else{
                this.$Util.MsgBox("【待提交】或【已驳回】状态才允许提交操作！", -1)
            }
        },

        // 受理
        onAudit() {
            if(this.billID === 0) return

            //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
            if(this.billModel.Status !== "B"){
                this.$Util.MsgBox("已提交状态才允许受理操作！", -1)
                return
            }

            this.$API.PostService('RequestBill/Audit', { BillID: this.billID }, resp => {
                this.$Util.MsgBox(resp.Message, 1)
                this.onGetModel()
            })
        },

        // 驳回
        onReject() {
            if(this.billID === 0) return

            //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
            if(this.billModel.Status !== "B"){
                this.$Util.MsgBox("【已提交】状态才允许驳回操作！", -1)
                return
            }

            this.showReason= true
        },

        onConfirmReject(){
            //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
            if(this.$Util.IsNull(this.ReasonStr)){
                this.$Util.MsgBox("请输入驳回说明！", -1)
                return
            }

            this.$API.PostService('RequestBill/Reject', { BillID: this.billID, Reason: this.ReasonStr }, resp => {
                this.showReason = false

                this.$Util.MsgBox(resp.Message, 1)
                this.onGetModel()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-request-detail{
    margin-bottom: 40px;
}

.bsy-bill-card{
    margin-bottom: 5px;
}
</style>
