<template>
    <temp-basic-list :Entity="entity" :Method="method" :FieldList="fieldList" :DetailPath="detailPath" :Operation="operation"
                     :import-method=importMethod :import-btn-disable="true"/>
</template>

<script>

import TempBasicList from "../../components/template/temp-basic-list";
export default {
    name: 'mediator-list',
    components: {TempBasicList},
    data() {
        return {
            entity: 'Mediator', //接口实体类名,
            importMethod: 'Data/Mediator/PostForm', //导入接口方法,
            method: 'GetList', //接口实体方法
            detailPath: '/mediator-detail',//详情的路径
            fieldList: [
                { label: '编码', prop: 'Number' },
                { label: '名称', prop: 'Name' },
            ],
            operation: ['view']
        }
    },
    mounted() {

    },

    methods: {

    }
}
</script>

<style></style>
