<template>
    <temp-bill-list :Entity="entity" :Method="method" :FieldList="fieldList" :DetailPath="detailPath" :Operation="operation"
                    :import-method=importMethod :add-btn="addBtn" operation="operation" :import-btn-disable="true" :query-placeholder="QueryPlaceholder"/>
</template>

<script>

import TempBillList from "../../components/template/temp-bill-list";
export default {
    name: 'request-list',
    components: {TempBillList},
    data() {
        return {
            entity: 'RequestBill', //接口实体类名,
            importMethod: 'Data/RequestBill/PostForm', //导入接口方法,
            method: 'GetList', //接口实体方法
            detailPath: '/request-detail', //详情的路径
            fieldList: [
                { label: '申请单号', prop: 'Number' },
                { label: '业务日期', prop: 'BizDateText' },
                { label: '单据状态', prop: 'StatusName' },
                { label: '申请方名称', prop: 'Enterprise.Name' },
                { label: '被申请方名称', prop: 'ResponseEnterprise' },
                { label: '应付金额', prop: 'OweAmount' },
            ],
            addBtn: this.$Util.GetStore("CurrentClientID") !== "Admin",
            QueryPlaceholder: '申请单号、申请方及被申请方名称',
            operation: ['view']
        }
    },
    mounted() {

    },

    methods: {

    }
}
</script>

<style></style>
