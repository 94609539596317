<template>
    <temp-basic-list :Entity="entity" :Method="method" :FieldList="fieldList" :DetailPath="detailPath" :Operation="operation"
    :add-btn="addBtn"/>
</template>

<script>

import TempBasicList from "../../components/template/temp-basic-list";
export default {
    //信用司法-司法申请单
    name: 'litigation-list',
    components: {TempBasicList},
    data() {
        return {
            entity: 'LitigationBill', //接口实体类名,
            method: 'GetList', //接口实体方法
            detailPath: '/litigation-detail',//详情的路径
            fieldList: [
                { label: '申请单号', prop: 'Number' },
                { label: '业务日期', prop: 'BizDateText' },
                { label: '申请方', prop: 'Enterprise.Name' },
                { label: '被申请方', prop: 'ResponseEnterprise' },
                { label: '申请主题', prop: 'Title', },
                { label: '期望受理', prop: 'ExpectedDate', },
            ],
            addBtn: this.$Util.GetStore("CurrentClientID") === "Enterprise",
            operation: ['view'],
        }
    },
    mounted() {

    },

    methods: {

    }
}
</script>

<style></style>
