<template>
    <temp-bill-list :Entity="entity" :Method="method" :FieldList="fieldList" :DetailPath="detailPath" :Operation="operation"
                    :allowPayStatus="true" :add-btn="addBtn" operation="operation" :query-placeholder="QueryPlaceholder"/>
</template>

<script>

import TempBillList from "../../components/template/temp-bill-list";
export default {
    name: 'response-list',
    components: {TempBillList},
    data() {
        return {
            entity: 'ResponseBill', //接口实体类名,
            method: 'GetList', //接口实体方法
            detailPath: '/response-detail', //详情的路径
            fieldList: [
                { label: '受理单号', prop: 'Number' },
                { label: '业务日期', prop: 'BizDateText' },
                { label: '单据状态', prop: 'StatusName' },
                { label: '付款状态', prop: 'PayStatusName' },
                { label: '申请方名称', prop: 'Enterprise.Name' },
                { label: '被申请方名称', prop: 'ResponseEnterprise' },
                { label: '应付金额', prop: 'OweAmount' },
                { label: '承诺金额', prop: 'PromiseAmount' },
                { label: '已付金额', prop: 'PaidAmount' },
                { label: '未付金额', prop: 'RemainAmount' },
                { label: '付款比例', prop: 'PayRate' },
            ],
            QueryPlaceholder:'受理单号、申请方及被申请方名称',
            addBtn: false,
            operation: ['view']
        }
    },
    mounted() {

    },

    methods: {

    }
}
</script>

<style></style>
