<template>
    <div>
        <el-form size="mini" label-position="right" label-width="100px" :disabled="disabled" class="ob-form">
            <slot></slot>
        </el-form>
    </div>
</template>

<script>
  export default {
    name: 'ObForm',
      props:{
          disabled:{
              type: Boolean,
              default: false
          }
      }
  }
</script>

<style lang="scss" scoped>
    .ob-form {
        background-color: white;
    }
</style>
