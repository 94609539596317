<template>
    <el-col :span="col" :offset="offset">
        <el-form-item class="el-form-item el-form-item__label" :label="label" :label-width="type === 'button' ? '0px' : labelWidth+'px'" >
            <el-button v-if="type === 'button'" :type="btnType" :icon="btnIcon === '' ? '' : 'el-icon-' + btnIcon" @click="btnClick">
                {{ btnText }}
            </el-button>
            <el-checkbox v-if="type === 'checkbox'" v-model="DataValue" />

            <!--文本-->
            <div v-if="type === 'text'">
                <el-input style="width: 192px" size="mini" clearable v-model="DataValue" :readonly="readonly" :placeholder="placeholder"/>
                <span v-if="required" class="required-style"> *</span>
            </div>
            <el-input-number v-if="type === 'number'" style="width: 192px" size="mini" clearable v-model="DataValue" :readonly="readonly" :placeholder="placeholder"/>
            <el-input-number v-if="type === 'precision'" style="width: 192px" size="mini" :precision="2" :step="0.1" clearable v-model="DataValue" :readonly="readonly" :placeholder="placeholder"/>
            <el-input v-if="type === 'password'" style="width: 192px" size="mini" clearable v-model="DataValue" show-password :readonly="readonly" :placeholder="placeholder" />
            <el-input v-if="type === 'textarea'" style="width: 192px" size="mini" clearable v-model="DataValue" type="textarea" rows="2" maxlength="200" show-word-limit :readonly="readonly" :placeholder="placeholder" />
            <el-date-picker v-if="type === 'date'" style="width: 192px" size="mini" clearable v-model="DataValue" type="date" value-format="yyyy-MM-dd" :readonly="readonly" :placeholder="placeholder" />
            <el-date-picker
                v-if="type === 'datetime'"
                size="mini"
                clearable
                v-model="DataValue"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                :readonly="readonly"
                :placeholder="placeholder"
            />
            <el-time-picker
                    v-if="type === 'timepicker'"
                    style="width: 192px"
                    size="mini"
                    clearable
                    v-model="DataValue"
                    :readonly="readonly"
                    :placeholder="placeholder">
            </el-time-picker>
            <el-select v-if="type === 'select'" style="width: 192px" size="mini" clearable v-model="DataValue" filterable :disabled="readonly" :placeholder="placeholder" :list="list">
                <el-option v-for="item in list" :key="item.ID" :label="item.Name" :value="item.ID" > </el-option>
            </el-select>

            <!--基础资料-->
            <div v-if="type === 'baselist'">
                <el-autocomplete
                        style="width: 192px"
                        clearable
                        v-model="DataValue"
                        :fetch-suggestions="querySearchAsync"
                        :trigger-on-focus="true"
                        @select="handleSelect"
                        :disabled="readonly"
                        :placeholder="placeholder"
                        :hide-loading="true"
                >
                    <el-button slot="append" icon="el-icon-search" size="mini" @click="btnClick" />

                    <template slot-scope="{ item }">
                        <div>{{ item.Name }}</div>
                    </template>
                </el-autocomplete>
                <span v-if="required" class="required-style"> *</span>
            </div>

            <el-input v-if="type === 'property'" style="width: 192px" size="mini" clearable v-model="DataValue" :readonly="readonly" :placeholder="placeholder" />

            <el-avatar v-if="type === 'avatar'" shape="square" :size="100" :src="DataValue"/>

            <el-cascader v-if="type === 'area'"
                size="large"
                :options="options"
                v-model="DataValue"
                @change="addressChange">
            </el-cascader>

            <b-s-y-upload v-if="type === 'upload'" ref="BSYUpload" :DataValue="DataValue" @onSuccessChange="onSuccessChange"/>
        </el-form-item>
    </el-col>
</template>

<script>
import { CodeToText, regionData } from 'element-china-area-data'
import BSYUpload from "@/components/bsy-ui/bsy-upload"

export default {
    name: 'ObFormItem',
    components: {BSYUpload},
    data(){
      return{
          options: regionData
      }
    },
    props: {
        label: {
            type: String,
            default: ''
        },
        labelWidth: {
            type: Number,
            default: 100
        },
        type: {
            type: String,
            default: ''
        },
        col: {
            type: Number,
            default: 6
        },
        offset: {
            type: Number,
            default: 0
        },
        readonly: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        list: {
            type: Array,
            default: null
        },
        btnType: {
            type: String,
            default: 'primary'
        },
        btnText: {
            type: String,
            default: ''
        },
        btnIcon: {
            type: String,
            default: ''
        },
        value: {},

        //调用接口的参数
        searchObj:{
            type: Object,
        },
        //需要反写父组件的字段
        modelField: {
            type: String,
            default: ''
        },
        //property类型组件的字段值
        field: {
            type: String,
            default: 'Name'
        },
        required:{
            type: Boolean,
            default: false
        },
    },
    computed: {
        DataValue: {
            get: function() {
                if(this.type === 'property' || this.type === 'baselist'){
                    if(this.$Util.IsNull(this.value))
                        return ''
                    else{
                        //判断传入的值是对象还是字符串 （[object String]、[object Object]）
                        if(Object.prototype.toString.call(this.value) === "[object Object]")
                            return this.value[this.field]
                        else
                            return this.value
                    }
                } else {
                    return this.value
                }
            },
            set: function(val) {
                this.$emit('input', val)
            }
        }
    },
    methods: {
        btnClick() {
            this.$emit('click')
        },

        //el-autocomplete搜索🔍
        querySearchAsync(queryString, cb) {
            let that = this
            let filter = ''
            if(!this.$Util.IsNull(queryString)){
                filter = ' Name.Contains("' + queryString + '") || Number.Contains("' + queryString + '")'
            }

            let param = {
                FilterStr: filter,
                Filter: [{}],
                Page: 1,
                Size: 50,
                keyword: '',
                Sort: ''
            }

            that.$BizApi.PostMethod(
                that.searchObj.entity,
                that.searchObj.method,
                param,
                (resp) => {
                    cb(resp.Data) // 调用 callback 返回建议列表的数据
                },
                // that.searchObj.lib
            )
        },
        handleSelect(data) {
            this.$emit('handleSelect', this.modelField, data)
        },

        // 省市区
        addressChange(arr) {
            console.log('arr', arr);
            console.log('省市区', CodeToText[arr[0]], CodeToText[arr[1]], CodeToText[arr[2]]);
            this.area = CodeToText[arr[0]] + CodeToText[arr[1]] + CodeToText[arr[2]]

            this.areaAdd = this.area +  this.address
            console.log('省市' , CodeToText   );

        },

        onSuccessChange(data) {
            this.$emit('onSuccessChange', data)
        },
    }
}
</script>

<style scoped>
    .el-form-item {
         margin-bottom: 0px;
    }
    .el-form-item__label{
            padding: 0 10px 0 0!important;
    }
    .required-style{
        font-weight: bold;
        color: red;
    }
</style>
