<template>
    <temp-basic-list :Entity="entity" :Method="method" :FieldList="fieldList" :Operation="operation"
                     :allowStatus=false :DoubleClick="false" :AddBtn="false"/>
</template>

<script>

import TempBasicList from "../../components/template/temp-basic-list";
export default {
    name: 'communityType-list',
    components: {TempBasicList},
    data() {
        return {
            entity: 'CommunityType', //接口实体类名,
            method: 'GetList', //接口实体方法
            fieldList: [
                { label: '社会组织类型编码', prop: 'Number' },
                { label: '社会组织类型名称', prop: 'Name' },
            ],
            operation: []
        }
    },
    mounted() {

    },

    methods: {

    }
}
</script>

<style></style>
