<template>
    <div class="float-right">
        <div class="float-right-box" @click="onLogin">
            <el-avatar shape="square" :size="60" :src="require('@/assets/icon/navbar/wode.png')"/>
            <div>注册登录</div>
        </div>
        <div class="float-right-box">
            <el-avatar shape="square" :size="60" :src="require('@/assets/icon/navbar/kefu.png')"/>
            <div>在线客服</div>
        </div>
        <div class="float-right-box">
            <el-avatar shape="square" :size="60" :src="require('@/assets/icon/navbar/weixin.png')"/>
            <div>微信公众号</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'bsy-menu-right',
    components: {},
    props:{

    },
    data() {
        return {

        }
    },
    mounted() {

    },
	methods: {
        onLogin(){
            // this.$router.replace({ name: 'user', query: {userid: this.newUserID} })
            this.$router.push( { name: 'login'} )
            // window.open('http://localhost:9090/#/login')
        },
	}
}
</script>
<style lang="scss">

.float-right{
    /* display: none; */
    position: fixed;
    right: 0;
    top: 365px;
    //margin-top: 0px;
    background: #F7F7F7;
    box-shadow: 0 0 4px 0 #999999;
    border-radius: 20px 0px 0px 20px;
    width: 90px;
    font-size: 14px;
    z-index: 10000;

    .float-right-box{
        height: 90px;
        width: 90px;
        text-align: center;
        cursor: pointer;
    }
    .el-avatar{
        background: #F7F7F7;
    }
    //.float-right-box :hover {
    //    box-shadow: 0 0 5px 0 #999999;
    //}
}
</style>
