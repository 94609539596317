<template>
    <div class="bsy-editor">
        <el-button-group style="margin-bottom: 10px">
            <el-button type="primary" icon="el-icon-check" :disabled="disabled" @click="onSelectImg">选择图片</el-button>
            <el-button type="primary" icon="el-icon-upload2" :disabled="disabled" @click="onUploadImg">上传图片</el-button>
        </el-button-group>

        <editor v-model="myValue" :init="init" :disabled="disabled" :key="tinymceFlag" />

        <!--选择图片弹框-->
        <el-dialog :visible.sync="selectImgVisible" >
            <div style="display: flex;position:absolute;top:10px;">
                <div style="font-size: 20px;"> 选择服务器图片 </div>
            </div>
            <div class="bsy-editor-checkDialog">
                <div v-if="serverImgList.length === 0"> 暂无图片数据，请上传 </div>
                <div v-else style="margin-bottom: 30px">
                    <el-checkbox size="medium" v-model="item.checkbox" @change="onImgCheckbox(item)" border style="height: auto;margin-top: 10px"
                                 v-for="(item,index) in serverImgList" :key="index" >
                        <img style="height: 90px; width: 90px;" :src="item.url" alt="">
                    </el-checkbox>
                </div>

                <div style="display: flex;position:absolute;bottom:10px;">
                    <div style="height: 10px" />
                    <div style="">
                        <el-button type="primary" plain @click="onChooseConfirm">确认选择</el-button>
                        <el-button type="success" plain @click="onChooseCancel">取消选择</el-button>
<!--                            <el-button type="info" plain @click="DeletePicture">删除</el-button>-->
                    </div>
                </div>
            </div>
        </el-dialog>

        <!-- 上传图片弹框-->
        <el-dialog :visible.sync="uploadImgVisible">
            <el-upload
                drag
                action=""
                :limit="1"
                :auto-upload="false"
                :file-list="uploadImgList"
                list-type="picture"
                :on-remove="onUploadRemove"
                :before-upload="onBeforeUpload"
                :on-change="onUploadChange"
                accept=".jpg, .jpeg, .png, .JPG, .JPEG"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传一张.jpg,.jpeg,.png,.JPG,.JPEG文件，且不超过2M</div>
            </el-upload>
            <div style="height: 30px"/>
            <div>
                <el-button type="primary" plain @click="onUploadSave">确认上传</el-button>
                <el-button type="success" plain @click="onChooseCancel">取消上传</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import tinymce from 'tinymce/tinymce'
    import Editor from '@tinymce/tinymce-vue'
    import 'tinymce/themes/silver'
    // 编辑器插件plugins
    // 更多插件参考：https://www.tiny.cloud/docs/plugins/
    import 'tinymce/plugins/image'// 插入上传图片插件
    import 'tinymce/plugins/media'// 插入视频插件
    import 'tinymce/plugins/table'// 插入表格插件
    import 'tinymce/plugins/lists'// 列表插件
    import 'tinymce/plugins/wordcount'// 字数统计插件
    import 'tinymce/plugins/preview'// 字数统计插件
    import 'tinymce/icons/default/icons.min.js'//图标

    export default {
        components: { Editor },
        props: {
            groupID: {
              type: Number,
              default: 0
            },
            value: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            // plugins: {
            //     type: [String, Array],
            //     default: ['advlist anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools importcss insertdatetime legacyoutput link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount']
            // },
            // toolbar: {
            //     type: [String, Array],
            //     default: 'undo redo |  formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table | removeformat'
            // }
        },
        watch: {
            value (newValue) {
                this.myValue = newValue
            },
            myValue (newValue) {
                this.$emit('input', newValue)
            }
        },
        data () {
            return {
                tinymceFlag: 'vue-tinymce-' + new Date(),
                init: {
                    language_url: './tinymce/langs/zh_CN.js',  //public目录下
                    language: 'zh_CN',
                    skin_url: './tinymce/skins/ui/oxide', //public目录下
                    height: 800,
                    menubar: false,//顶部菜单栏显示
                    // 父组件传入 或者 填写个默认的插件 要选用什么插件都可以 去官网可以查到
                    plugins: ['preview advlist anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools importcss insertdatetime legacyoutput link lists media nonbreaking noneditable pagebreak paste print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'],
                    // 工具栏 我用到的也就是lists image media table wordcount 这些 根据需求而定
                    toolbar: ['preview bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample fontsizeselect fontselect ' , 'hr bullist numlist link image charmap anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen'],
                    image_dimensions: false, //是否启用上传图片功能
                    // images_upload_url: 'https://www.zoonqi.com', //上传路径
                    // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
                    // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
                    draggable_modal: true ,     // 窗体拖动模式
                    min_width:400,
                    // 官网抄的图片上传 项目如果用了vue-resource可以用$http 因为比较懒就没改
                    // images_upload_handler: (blobInfo, success, failure) => {
                    //     var xhr, formData;
                    //     xhr = new XMLHttpRequest();
                    //     xhr.withCredentials = false;
                    //     xhr.open('POST', `https://` + "basic/upload/");
                    //
                    //     xhr.onload = function() {
                    //         var json;
                    //         if (xhr.status != 200) {
                    //             failure('HTTP Error: ' + xhr.status);
                    //             return;
                    //         }
                    //         json = JSON.parse(xhr.responseText);
                    //
                    //         // if (!json || typeof json.img_url != 'string') {
                    //         //   failure('Invalid JSON: ' + xhr.responseText);
                    //         //   return;
                    //         // }
                    //         // console.log(json)
                    //         success(json.img_url);
                    //     };
                    //     formData = new FormData();
                    //     formData.append('file', blobInfo.blob(), blobInfo.filename());
                    //     console.log('formData', formData)
                    //     xhr.send(formData);
                    //
                    // },
                    selector: 'textarea', // change this value according to your HTML
                    convert_urls: false , //这个参数加上去就可以了，路径就带域名了
                    theme_advanced_buttons3_add : "preview",
                    plugin_preview_width : "1200", //This do the trick
                    plugin_preview_height : "800"
                },
                myValue: this.value,

                //富文本图片参数
                selectImgVisible: false,   // 选择图片
                uploadImgVisible: false,   // 上传图片
                serverImgList:[], // 服务器图片集合
                confirmImgList:[], // 已选择的图片集合
                uploadImgList:[], // 上传图片集合
            }
        },
        mounted () {
            tinymce.init({})
        },
        methods: {
            /**
             * start 选择服务器图片
             */
            // 按钮 - 选择图片
            onSelectImg(){
                this.confirmImgList = []// 添加到富文本框中的图片集合
                this.serverImgList = []// 服务器图片数组
                this.selectImgVisible = true

                let params = {
                    GroupID: this.groupID
                }
                this.$API.PostService('File/GetImageList', params, (resp) => {
                    if(resp.Status === 1){
                        for(let i = 0 ; i < resp.Data.length; i++){
                            let newObj = resp.Data[i]
                            newObj.checkbox = false

                            this.serverImgList.push(newObj)
                        }
                    }
                })
            },

            // 选择图片 - 复选框勾选图片事件
            onImgCheckbox(item){
                if(item.checkbox === true){
                    this.confirmImgList.push(item)
                }else{
                    let index = this.confirmImgList.findIndex(item => item.key === this.confirmImgList.key);
                    this.confirmImgList.splice(index, 1);
                }
            },

            // 选择图片 - 确认按钮
            onChooseConfirm(){
                let chooseList = this.confirmImgList.filter((item) => {
                    return item.checkbox === true
                })

                let content = ""
                for(let i = 0 ; i < chooseList.length; i++){
                    let url = "<img src="  + chooseList[i].url +" alt=\"\"/>"
                    content += url;
                }
                this.$emit('onAddContent', content)

                this.confirmImgList = []
                this.selectImgVisible = false
            },

            // 选择图片 - 取消选择
            onChooseCancel(){
                this.uploadImgList = []
                this.selectImgVisible = false// 取消选择图片框
                this.uploadImgVisible = false// 取消上传图片框
            },
            /**
             * end 选择服务器图片
             */


            /**
             * start 上传图片
             */
            // 按钮 - 上传图片
            onUploadImg(){
                this.confirmImgList = [] // 已选择要添加到富文本框中的图片
                this.uploadImgList = [] // 需要上传的图片集合
                this.serverImgList = [] // 服务器上的图片集合

                this.uploadImgVisible = true
            },

            // 上传图片 - 确定上传
            onUploadSave(){
                if(this.uploadImgList.length == 0){
                    this.$Util.MsgBox('请先选择文件！')
                    return
                }

                //定义参数
                let formData = new FormData()
                formData.append('GroupID', this.groupID+"")

                //遍历图片
                for (const pic of this.uploadImgList){
                    formData.append('File', pic.raw)
                }

                this.$API.PostService('File/UploadImage/PostForm', formData, resp => {
                    this.$Util.MsgBox("上传成功", 1)
                    // 上传成功并
                    if(resp.Status === 1){
                        // 加入富文本框
                        let content = ""
                        for(let i = 0 ; i < resp.Data.length; i++){
                            // let url = "<img src=" + resp.Data[i].url +" alt=\"\"/>"
                            let url = "<img src=" + resp.Data[i] +" alt=\"\"/>"
                            content += url;
                        }
                        this.uploadImgVisible = false
                        console.log("content", content)
                        this.$emit('onAddContent', content)
                    }
                })
            },

            // 上传图片 - 上传成功指改变事件
            onUploadChange(res, file){
                console.log('onUploadChange res', res)
                this.uploadImgList = file
            },

            // 上传图片-删除图片
            onUploadRemove(file, fileList) {
                console.log('onUploadRemove file', file)
                console.log('onUploadRemove fileList', fileList)
                this.uploadImgList = []
                this.selectImgVisible = false    // 取消选择图片框
                this.selectImgVisible = false    // 取消上传图片框
            },

            // 上传图片 - 上传图片限制规格大小
            onBeforeUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2
                if(!isLt2M) {
                    this.$Util.MsgBox('上传文件大小不能超过 2MB', -1)
                }
                return isLt2M
            },
            /**
             * end 上传图片
             */
        },
    }
</script>
<style lang="scss" scoped>
    .bsy-editor{
        margin-bottom: 30px;

        .bsy-editor-checkDialog {
            justify-content: center;
            align-items: Center;
            overflow: auto;
            margin-bottom: 40px;
            height: 400px !important;
            .el-dialog {
                margin: 0 auto !important;
                /*height: 600px !important;*/
                overflow: hidden;
            }
        }
    }
</style>
