<template>
    <temp-bill-list :Entity="entity" :Method="method" :FieldList="fieldList" :DetailPath="detailPath" :Operation="operation"
                    :add-btn="addBtn" :allowStatus="allowStatus" :date-pick-list="datePickList" :query-placeholder="QueryPlaceholder"/>
</template>

<script>

import TempBillList from "../../components/template/temp-bill-list";
export default {
    name: 'evidence-list',
    components: {TempBillList},
    data() {
        return {
            entity: 'EvidenceBill', //接口实体类名,
            method: 'GetList', //接口实体方法
            detailPath: '/evidence-detail', //详情的路径
            fieldList: [
                { label: '存证单号', prop: 'Number' },
                { label: '业务日期', prop: 'BizDateText' },
                { label: '企业', prop: 'Enterprise.Name' },
                { label: '客户名称', prop: 'Customer' },
                { label: '合同日期', prop: 'ContractDate' },
                { label: '应收日期', prop: 'ReceivableDate' },
                { label: '已收金额', prop: 'ReceivedAmount' },
                { label: '未收金额', prop: 'RemainAmount', },
            ],
            addBtn: this.$Util.GetStore("CurrentClientID") === "Enterprise",
            allowStatus: false,
            QueryPlaceholder: '存证单号、企业、客户名称',
            operation: ['view'],
            datePickList:[
                { label: '业务日期', value: 'BizDate' },
            ]
        }
    },
    mounted() {

    },

    methods: {

    }
}
</script>

<style></style>
