<template>
    <temp-basic-list :Entity="entity" :Method="method" :FieldList="fieldList" :DetailPath="detailPath" :Operation="operation" :add-btn="true"/>
</template>

<script>

import TempBasicList from "../../components/template/temp-basic-list";
export default {
    name: 'banner-list',
    components: {TempBasicList},
    data() {
        return {
            entity: 'Banner', //接口实体类名,
            method: 'GetList', //接口实体方法
            detailPath: '/banner-detail',//详情的路径
            fieldList: [
                { label: '状态', prop: 'StatusName' },
                { label: '名称', prop: 'Number' },
                { label: '名称', prop: 'Name' },
                { label: '图片', prop: 'ImageUrl', type:'image' },
            ],
            operation: ['view','delete']
        }
    },
    mounted() {

    },

    methods: {

    }
}
</script>

<style></style>
