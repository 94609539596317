<template>
    <div class="page-evidence-detail">
        <el-form :model="billModel" :rules="rules" ref="ruleForm" label-position="right" label-width="100px" :disabled="disabled">
            <el-card class="bsy-bill-card">
                <div slot="header">
                    <span>基本信息</span>
                </div>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="存证单号">
                            <el-input readonly v-model="billModel.Number" placeholder="保存后自动生成" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="业务日期" prop="BizDateText">
                            <el-input readonly v-model="billModel.BizDateText" />
                        </el-form-item>
                    </el-col>
<!--                    <el-col :span="6">-->
<!--                        <el-form-item label="单据状态" prop="StatusName">-->
<!--                            <el-input readonly v-model="billModel.StatusName" />-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                    <el-col :span="6">
                        <el-form-item label="申请企业">
                            <el-input readonly v-model="billModel.Enterprise.Name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="联系电话">
                            <el-input readonly v-model="billModel.EnterpriseUser.Phone" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                <el-col :span="6">
                        <el-form-item label="客户名称" prop="Customer">
                            <el-input v-model="billModel.Customer" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="合同日期" prop="ContractDate">
                            <el-date-picker v-model="billModel.ContractDate" type="date" placeholder="请选择合同日期" style="width: 100%">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="出库日期" prop="OutStockDate">
                            <el-date-picker v-model="billModel.OutStockDate" type="date" placeholder="请选择出库日期" style="width: 100%">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="开票日期" prop="InvoiceDate">
                            <el-date-picker v-model="billModel.InvoiceDate" type="date" placeholder="请选择开票日期" style="width: 100%">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="应收日期" prop="ReceivableDate">
                            <el-date-picker v-model="billModel.ReceivableDate" type="date" placeholder="请选择应收日期" style="width: 100%">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="应收金额" prop="ReceivableAmount">
                            <el-input v-model="billModel.ReceivableAmount" type="number" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="已收金额" prop="ReceivedAmount">
                            <el-input v-model="billModel.ReceivedAmount" type="number" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="未收金额" prop="RemainAmount">
                            <el-input v-model="billModel.RemainAmount" type="number" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

            <el-card class="bsy-bill-card">
                <div slot="header">
                    <span>存证信息</span>
                </div>
                <el-row :gutter="10">
                    <el-col :span="8" class="bsy-bill-upload-card">
                        <el-upload
                            :auto-upload="false"
                            action=""
                            :limit="6"
                            list-type="picture"
                            multiple
                            :on-preview="handlePictureCardPreview"
                            :on-remove="onRemovePicture"
                            :on-exceed="handleExceed"
                            :before-upload="beforeAvatarUpload"
                            :on-change="onSuccessUploadContract"
                            :file-list="billModel.Contract"
                            accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                            :disabled="disabled"
                        >
                            <span style="font-size: 14px;margin: 0px 20px">销售合同</span>
                            <el-button size="small" :disabled="disabled" type="primary">点击上传</el-button>
                        </el-upload>
                    </el-col>
                    <el-col :span="8" class="bsy-bill-upload-card">
                        <el-upload
                            action=""
                            :limit="6"
                            :multiple="true"
                            :auto-upload="false"
                            list-type="picture"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="onRemovePicture"
                            :on-exceed="handleExceed"
                            :before-upload="beforeAvatarUpload"
                            :on-change="onSuccessUploadOutStock"
                            :file-list="billModel.OutStock"
                            accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                            :disabled="disabled"
                        >
                            <span style="font-size: 14px;margin: 0px 20px">销售出库</span>
                            <el-button size="small" :disabled="disabled" type="primary">点击上传</el-button>
                        </el-upload>
                    </el-col>
                    <el-col :span="8" class="bsy-bill-upload-card">
                        <el-upload
                            action=""
                            :limit="6"
                            :multiple="true"
                            :auto-upload="false"
                            list-type="picture"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="onRemovePicture"
                            :on-exceed="handleExceed"
                            :before-upload="beforeAvatarUpload"
                            :on-change="onSuccessUploadInvoice"
                            :file-list="billModel.Invoice"
                            accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                            :disabled="disabled"
                        >
                            <span style="font-size: 14px;margin: 0px 20px">销售发票</span>
                            <el-button size="small" :disabled="disabled" type="primary">点击上传</el-button>
                        </el-upload>
                    </el-col>
                    <el-col :span="8" class="bsy-bill-upload-card">
                        <el-upload
                            action=""
                            :limit="6"
                            :multiple="true"
                            :auto-upload="false"
                            list-type="picture"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="onRemovePicture"
                            :on-exceed="handleExceed"
                            :before-upload="beforeAvatarUpload"
                            :on-change="onSuccessUploadReceivable"
                            :file-list="billModel.ReceivableNotice"
                            accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                            :disabled="disabled"
                        >
                            <span style="font-size: 14px;margin: 0px 20px">付款提示函</span>
                            <el-button size="small" :disabled="disabled" type="primary">点击上传</el-button>
                        </el-upload>
                    </el-col>
                    <el-col :span="8" class="bsy-bill-upload-card">
                        <el-upload
                            action=""
                            :limit="6"
                            :multiple="true"
                            :auto-upload="false"
                            list-type="picture"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="onRemovePicture"
                            :on-exceed="handleExceed"
                            :before-upload="beforeAvatarUpload"
                            :on-change="onSuccessUploadOverdue"
                            :file-list="billModel.OverdueNotice"
                            accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                            :disabled="disabled"
                        >
                            <span style="font-size: 14px;margin: 0px 20px">逾期提醒函</span>
                            <el-button size="small" :disabled="disabled" type="primary">点击上传</el-button>
                        </el-upload>
                    </el-col>
                    <el-col :span="8" class="bsy-bill-upload-card">
                        <el-upload
                            action=""
                            :limit="6"
                            :multiple="true"
                            :auto-upload="false"
                            list-type="picture"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="onRemovePicture"
                            :on-exceed="handleExceed"
                            :before-upload="beforeAvatarUpload"
                            :on-change="onSuccessUploadPress"
                            :file-list="billModel.PressNotice"
                            accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                            :disabled="disabled"
                        >
                            <span style="font-size: 14px;margin: 0px 20px">催款通知函</span>
                            <el-button size="small" :disabled="disabled" type="primary">点击上传</el-button>
                        </el-upload>
                    </el-col>
                </el-row>
            </el-card>

            <el-card class="bsy-bill-card">
                <div slot="header">
                    <span>其他信息</span>
                </div>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="创建时间">
                                <el-input readonly v-model="billModel.CreatedOn" placeholder="保存后自动生成"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="创建人">
                                <el-input readonly v-model="billModel.CreatedBy" placeholder="保存后自动生成"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="修改时间">
                                <el-input readonly v-model="billModel.ModifiedOn" placeholder="保存后自动生成"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="修改人">
                                <el-input readonly v-model="billModel.ModifiedBy" placeholder="保存后自动生成"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
            </el-card>
        </el-form>
        <bsy-buttons ref="BSYButtons" :buttons="buttons" :parentVue="thisVue" />
        <bsy-progress ref="BSYProgress" />

        <el-dialog :visible.sync="imageDialog">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BsyButtons from "../../components/bsy-ui/bsy-buttons";
import BsyProgress from "../../components/bsy-ui/bsy-progress";

export default {
    name: 'evidence-detail',
    components: {BsyProgress, BsyButtons},
    computed: {
        ...mapGetters(['USER', 'collapsed']),
    },
    data() {
        var checkAmount = (rule, value, callback) => {
            if (Number(value) > 0) {
                callback();
            } else {
                callback(new Error('请输入正确的金额'));
            }
        };

        return {
            thisVue: this,
            buttons: [],

            currentUser: {},
            // 实体类名
            entity: 'EvidenceBill',

            disabled: false,
            billID: 0, //单据表头ID
            billModel: {
                EnterpriseUser: {Name: ''},
                Enterprise: {Name: ''},
            },
            rules: {
                ReceivableAmount: [
                    { required: true, validator: checkAmount, trigger: 'blur' },
                ],
                // ReceivedAmount: [
                //     { validator: checkAmount, trigger: 'blur' },
                // ],
                // RemainAmount: [
                //     { validator: checkAmount, trigger: 'blur' },
                // ],
                Customer: [
                    { required: true, message: '请输入客户名称', trigger: 'blur' },
                ],
                ContractDate: [
                    { required: true, message: '请输入合同日期', trigger: 'blur' },
                ],
                OutStockDate: [
                    { required: true, message: '请输入出库日期', trigger: 'blur' },
                ],
                InvoiceDate: [
                    { required: true, message: '请输入开票日期', trigger: 'blur' },
                ],
                ReceivableDate: [
                    { required: true, message: '请输入应收日期', trigger: 'blur' },
                ],
            },

            EnterpriseList: [],
            EnterpriseTypeList: [],

            imageDialog: false,
            dialogImageUrl: '',
            contractFileList: [],
            outstockFileList: [],
            invoiceFileList: [],
            receivableFileList: [],
            overdueFileList: [],
            pressFileList: [],
            deleteFileList: [],
        }
    },
    mounted() {
        this.billID = Number(this.$route.query.id)
        if (this.billID === 0) {
            this.onCreate()
        }else{
            this.onGetModel()
        }
    },
    methods: {
        // 上传选择图片
        onSuccessUploadContract(res, file){
            this.contractFileList = file
        },
        onSuccessUploadOutStock(res, file){
            this.outstockFileList = file
        },
        onSuccessUploadInvoice(res, file){
            this.invoiceFileList = file
        },
        onSuccessUploadReceivable(res, file){
            this.receivableFileList = file
        },
        onSuccessUploadOverdue(res, file){
            this.overdueFileList = file
        },
        onSuccessUploadPress(res, file){
            this.pressFileList = file
        },

        // 上传图片限制类型及大小
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2
            if(!isLt2M) {
                this.$Util.MsgBox('上传文件大小不能超过 2MB!', -1)
            }
            return isLt2M
        },

        // 删除图片
        onRemovePicture(file) {
            if(!this.$Util.IsNull(file.key)){
                this.deleteFileList.push(file.key)
            }
        },

        handleExceed() {
            this.$Util.MsgBox(`最多允许上传 6 张文件！`);
        },

        handlePictureCardPreview(file) {
            if(file.status === "ready"){
                if(file.raw.type === "image/jpg" || file.raw.type === "image/jpeg" || file.raw.type === "image/png"
                    || file.raw.type === "image/JPG" || file.raw.type === "image/JPEG"){
                    this.dialogImageUrl = file.url;
                    this.imageDialog = true;
                }else{
                    this.$Util.MsgBox("非图片文件，暂不支持预览！", 0)
                }
            }else if(file.status === "success"){
                if(file.extname === "jpg" || file.extname === "jpeg" || file.extname === "png"
                    || file.extname === "JPG" || file.extname === "JPEG"){
                    this.dialogImageUrl = file.url;
                    this.imageDialog = true;
                }else{
                    this.$confirm('是否将文件下载到本地？', '提示' , {
                        confirmButtonText: '下载',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        window.open(file.link)
                    })
                }
            }
        },

        onCreate(){
            this.$API.Create(this.entity, (resp) => {
                this.billModel = resp.Data

                this.onSetIdentity()
            })
        },

        onGetModel() {
            this.$API.GetModel(this.entity, this.billID, (resp) => {
                this.billModel = resp.Data

                this.onSetIdentity()
            })
        },

        onSetIdentity(){
            let status = this.billModel.Status
            let currentClientID = this.$Util.GetStore("CurrentClientID")
            switch (currentClientID){
                //管理员
                case "Admin":
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    this.disabled = (!(status === "B"))
                    this.buttons = [
                        { label: '保存', event: 'onSave', icon: 'el-icon-check', disabled: this.disabled },
                    ]
                    break

                //企业机构
                case "Enterprise":
                    this.disabled = (!(status === "A" || status === "D"))
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    this.buttons = [
                        { label: '保存', event: 'onSave', icon: 'el-icon-check', disabled: this.disabled },
                        // { label: '提交', event: 'onSubmit', icon: 'el-icon-finished', disabled: this.disabled }
                    ]
                    break

                //调解机构
                case "Mediator":
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    this.disabled = (status !== "B")
                    this.buttons = [
                        { label: '受理', event: 'onAudit', icon: 'el-icon-refresh-left', disabled: this.disabled },
                        { label: '驳回', event: 'onReject', icon: 'el-icon-finished', disabled: this.disabled },
                    ]
                    break

                //社会组织
                case "Community":
                    this.disabled = (!(status === "A" || status === "D"))
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    this.buttons = [
                        { label: '保存', event: 'onSave', icon: 'el-icon-check', disabled: this.disabled },
                        { label: '提交', event: 'onSubmit', icon: 'el-icon-finished', disabled: this.disabled }
                    ]
                    break
            }
        },

        // 保存
        async onSave() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    if(this.billModel.Status === "A" || this.billModel.Status === "D"){
                        this.$API.Save(this.entity, this.billModel, async resp => {
                            this.billID = resp.Data.ID

                            await this.onUpload()

                            this.$Util.MsgBox(resp.Message, 1)
                        })
                    }else{
                        this.$Util.MsgBox("【待提交】或【已驳回】状态才允许保存操作！", -1)
                    }
                } else {
                    return false;
                }
            })
        },

        //上传附件
        async onUpload(){
            //销售合同
            if(this.contractFileList.length > 0){
                let formData = new FormData()
                formData.append('EnterpriseID', this.billModel.EnterpriseID)
                formData.append('BillNo', this.billModel.Number)
                formData.append('Type', "contract")
                //遍历图片
                for (const pic of this.contractFileList){
                    formData.append('File', pic.raw)
                }
                //20221115 菜狗更换接口
                // await this.$API.SyncPostService("Evidence/Upload/PostForm", formData)
                await this.$API.SyncPostService("File/UploadEvidence/PostForm", formData)
            }

            //销售出库
            if(this.outstockFileList.length > 0){
                let formData = new FormData()
                formData.append('EnterpriseID', this.billModel.EnterpriseID)
                formData.append('BillNo', this.billModel.Number)
                formData.append('Type', "outstock")
                //遍历图片
                for (const pic of this.outstockFileList){
                    formData.append('File', pic.raw)
                }
                await this.$API.SyncPostService("File/UploadEvidence/PostForm", formData)
            }

            //销售发票
            if(this.invoiceFileList.length > 0){
                let formData = new FormData()
                formData.append('EnterpriseID', this.billModel.EnterpriseID)
                formData.append('BillNo', this.billModel.Number)
                formData.append('Type', "invoice")
                //遍历图片
                for (const pic of this.invoiceFileList){
                    formData.append('File', pic.raw)
                }
                await this.$API.SyncPostService("File/UploadEvidence/PostForm", formData)
            }

            //付款提示函
            if(this.receivableFileList.length > 0){
                let formData = new FormData()
                formData.append('EnterpriseID', this.billModel.EnterpriseID)
                formData.append('BillNo', this.billModel.Number)
                formData.append('Type', "receivable")
                //遍历图片
                for (const pic of this.receivableFileList){
                    formData.append('File', pic.raw)
                }
                await this.$API.SyncPostService("File/UploadEvidence/PostForm", formData)
            }

            //逾期提醒函
            if(this.overdueFileList.length > 0){
                let formData = new FormData()
                formData.append('EnterpriseID', this.billModel.EnterpriseID)
                formData.append('BillNo', this.billModel.Number)
                formData.append('Type', "overdue")
                //遍历图片
                for (const pic of this.overdueFileList){
                    formData.append('File', pic.raw)
                }
                await this.$API.SyncPostService("File/UploadEvidence/PostForm", formData)
            }

            //催款通知函
            if(this.pressFileList.length > 0){
                let formData = new FormData()
                formData.append('EnterpriseID', this.billModel.EnterpriseID)
                formData.append('BillNo', this.billModel.Number)
                formData.append('Type', "press")
                //遍历图片
                for (const pic of this.pressFileList){
                    formData.append('File', pic.raw)
                }
                await this.$API.SyncPostService("File/UploadEvidence/PostForm", formData)
            }

            //删除文件
            if(this.deleteFileList.length > 0){
                await this.$API.SyncPostService("File/RemoveEvidence", { FileKeys: this.deleteFileList })
            }

            this.onGetModel()
        }
    }
}
</script>

<style lang="scss" scoped>
.page-evidence-detail{
    margin-bottom: 40px;

    .bsy-bill-card{
        margin-bottom: 5px;
    }

    .bsy-bill-upload-card{
        box-shadow: 0 0 1px #c1c1c1;
        padding: 10px;
        //margin-right: 10px;
        height: 700px;
    }
}
</style>
