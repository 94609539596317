<template>
    <temp-basic-list :Entity="entity" :Method="method" :FieldList="fieldList" :DetailPath="detailPath"
                     :Operation="operation" :add-btn="true" :import-btn-disable="false" :query-placeholder="QueryPlaceholder"/>
</template>

<script>

import TempBasicList from "../../components/template/temp-basic-list";
export default {
    name: 'article-list',
    components: {TempBasicList},
    data() {
        return {
            entity: 'Article', //接口实体类名,
            method: 'GetList', //接口实体方法
            detailPath: '/article-detail',//详情的路径
            fieldList: [
                { label: '顺序', prop: 'Seq' },
                { label: '状态', prop: 'StatusName' },
                { label: '日期', prop: 'BizDateText' },
                { label: '分类', prop: 'Group.Name' },
                { label: '标题', prop: 'Name' },
                { label: '作者', prop: 'Author' },
                { label: '图片', prop: 'ImageUrl', type:'image' },
            ],
            QueryPlaceholder: '分类、标题',
            operation: ['view','delete']
        }
    },
    mounted() {

    },

    methods: {

    }
}
</script>

<style></style>
