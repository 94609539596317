<template>
    <div class="page-request-detail">
        <el-form :model="billModel" :rules="rules" ref="ruleForm" label-position="right" label-width="100px" :disabled="disabled">
            <el-card class="bsy-bill-card">
                <div slot="header">
                    <span>申请信息</span>
                    <el-button-group>
                        <el-button type="primary" style="padding: 3px;margin-left: 20px" @click="onShowProgress" :disabled="false">进度</el-button>
                        <el-button type="primary" style="padding: 3px;" @click="onRelevantBill" :disabled="false">受理单</el-button>
                        <el-button type="primary" style="padding: 3px;" @click="onEvidenceBill" :disabled="false">存证单</el-button>
                    </el-button-group>
                </div>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="单据状态" prop="StatusName">
                            <el-input readonly v-model="billModel.StatusName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="申请单号">
                            <el-input readonly v-model="billModel.Number" placeholder="保存后自动生成" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="业务日期" prop="BizDateText">
                            <el-input readonly v-model="billModel.BizDateText" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="关联存证" prop="EvidenceBillID">
                            <el-select v-model="billModel.EvidenceBillID" placeholder="请选择被关联存证" @change="onSelEvidence" style="width: 100%" >
<!--                                <div v-for="(item,index) in EvidenceList" :key="index">-->
<!--                                    <el-option :label="item.Name" :value="item.ID" />-->
<!--                                </div>-->
                                <el-option
                                    v-for="item in EvidenceList"
                                    :key="item.ID"
                                    :label="item.Number"
                                    :value="item.ID">
                                    <span style="float: left">{{ item.Number }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.Customer }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
<!--                        <el-form-item label="申请企业">-->
<!--                            <el-input readonly v-model="billModel.Enterprise.Name" />-->
<!--                        </el-form-item>-->
                        <el-form-item label="申请企业" prop="EnterpriseID">
                            <el-select v-model="billModel.EnterpriseID" placeholder="请选择被申请方企业" @change="onSelResponseEnterprise" style="width: 100%" >
                                <div v-for="(item,index) in EnterpriseList" :key="index">
                                    <el-option :label="item.Name" :value="item.ID" />
                                </div>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="申请人">
                            <el-input readonly v-model="billModel.EnterpriseUser.Name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="申请方电话">
                            <el-input readonly v-model="billModel.EnterpriseUser.Phone" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="申请备注">
                            <el-input v-model="billModel.Remark" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

            <el-card class="bsy-bill-card">
                <div slot="header">
                    <span>被申请方</span>
                </div>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="企业名称" prop="ResponseEnterprise">
                            <el-input v-model="billModel.ResponseEnterprise" placeholder="请输入被申请方企业名称" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="企业类型" prop="ResponseEnterpriseTypeID">
                            <el-select v-model="billModel.ResponseEnterpriseTypeID" placeholder="请选择被申请方企业类型" @change="onSelResponseEnterpriseType" style="width: 100%" >
                                <div v-for="(item,index) in EnterpriseTypeList" :key="index">
                                    <el-option :label="item.Name" :value="item.ID" />
                                </div>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="被申请人" prop="ResponseContact">
                            <el-input v-model="billModel.ResponseContact" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="联系电话" prop="ResponsePhone">
                            <el-input v-model="billModel.ResponsePhone" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="省市区域">
                            <el-cascader style="width: 100%" :options="areaOptions" v-model="areaSelVal" @change="onAreaChange" />
                        </el-form-item>
                    </el-col>
<!--                    <el-col :span="6">-->
<!--                        <el-form-item label="所在省份">-->
<!--                            <el-input v-model="billModel.ResponseProvince" />-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="6">-->
<!--                        <el-form-item label="所在城市">-->
<!--                            <el-input v-model="billModel.ResponseCity" />-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="6">-->
<!--                        <el-form-item label="所在地区">-->
<!--                            <el-input v-model="billModel.ResponseDistrict" />-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                    <el-col :span="12">
                        <el-form-item label="公司地址" prop="ResponseAddress">
                            <el-input v-model="billModel.ResponseAddress" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="营业执照" prop="ResponseLicense">
                            <el-input v-model="billModel.ResponseLicense" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="应付金额" prop="OweAmount">
                            <el-input v-model="billModel.OweAmount" type="number" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="逾期起始" prop="OweStartDate">
                            <el-date-picker v-model="billModel.OweStartDate" type="date" placeholder="请选择逾期起始" style="width: 100%">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="18">
                        <el-form-item label="申请理由" prop="Remark">
                            <el-input v-model="billModel.Remark" type="textarea" :rows="3" placeholder="请选择逾期起始" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>

<!--            <el-card class="bsy-bill-card">-->
<!--                <div slot="header">-->
<!--                    <span>调解机构</span>-->
<!--                </div>-->
<!--                <el-row>-->
<!--                    <el-col :span="6">-->
<!--                        <el-form-item label="调解机构">-->
<!--                            <el-input v-model="billModel.MediatorName" placeholder="受理后自动生成" />-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="6">-->
<!--                        <el-form-item label="调解员">-->
<!--                            <el-input v-model="billModel.MediatorUserName" placeholder="受理后自动生成" />-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="6">-->
<!--                        <el-form-item label="调解机构电话">-->
<!--                            <el-input v-model="billModel.MediatorPhone" placeholder="受理后自动生成" />-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="6">-->
<!--                        <el-form-item label="调解员电话">-->
<!--                            <el-input v-model="billModel.MediatorUserPhone" placeholder="受理后自动生成"/>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                </el-row>-->
<!--                <el-row>-->
<!--                    <el-col :span="6">-->
<!--                        <el-form-item label="调解开始日期">-->
<!--                            <el-input readonly v-model="billModel.MediateStartDate" placeholder="受理后自动生成"/>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="6">-->
<!--                        <el-form-item label="驳回时间">-->
<!--                            <el-input readonly v-model="billModel.RejectedOn" placeholder="驳回后自动生成"/>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="6">-->
<!--                        <el-form-item label="驳回人">-->
<!--                            <el-input readonly v-model="billModel.RejectedBy" placeholder="驳回后自动生成"/>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="6">-->
<!--                        <el-form-item label="驳回原因">-->
<!--                            <el-input readonly v-model="billModel.RejectReason" placeholder="驳回后自动生成"/>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                </el-row>-->
<!--            </el-card>-->

<!--            <el-card class="bsy-bill-card">-->
<!--                <div slot="header">-->
<!--                    <span>代理组织</span>-->
<!--                </div>-->
<!--                <el-row>-->
<!--                    <el-col :span="6">-->
<!--                        <el-form-item label="代理组织">-->
<!--                            <el-input readonly v-model="billModel.CommunityName" placeholder="保存后自动生成" />-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="6">-->
<!--                        <el-form-item label="组织电话">-->
<!--                            <el-input readonly v-model="billModel.CommunityPhone" placeholder="保存后自动生成" />-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="6">-->
<!--                        <el-form-item label="代理人">-->
<!--                            <el-input readonly v-model="billModel.CommunityUserName" placeholder="保存后自动生成" />-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col :span="6">-->
<!--                        <el-form-item label="代理电话">-->
<!--                            <el-input readonly v-model="billModel.CommunityUserPhone" placeholder="保存后自动生成"/>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
<!--                </el-row>-->
<!--            </el-card>-->

            <el-card class="bsy-bill-card">
                <div slot="header">
                    <span>其他信息</span>
                </div>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="创建时间">
                                <el-input readonly v-model="billModel.CreatedOn" placeholder="保存后自动生成"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="创建人">
                                <el-input readonly v-model="billModel.CreatedBy" placeholder="保存后自动生成"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="修改时间">
                                <el-input readonly v-model="billModel.ModifiedOn" placeholder="保存后自动生成"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="修改人">
                                <el-input readonly v-model="billModel.ModifiedBy" placeholder="保存后自动生成"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
            </el-card>
        </el-form>
        <bsy-buttons ref="BSYButtons" :buttons="buttons" :parentVue="thisVue" />
        <bsy-progress ref="BSYProgress" />

        <el-dialog title="驳回" :visible.sync="showReason" width="30%" center>
            <el-input v-model="ReasonStr" type="textarea" :rows="3" placeholder="请输入驳回说明"/>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showReason = false">取 消</el-button>
                <el-button type="primary" @click="onConfirmReject">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BsyButtons from "../../components/bsy-ui/bsy-buttons";
import BsyProgress from "../../components/bsy-ui/bsy-progress";
import { CodeToText, regionData } from 'element-china-area-data'

export default {
    name: 'request-detail',
    components: {BsyProgress, BsyButtons},
    computed: {
        ...mapGetters(['USER', 'collapsed']),
    },
    data() {
        var checkOweAmount = (rule, value, callback) => {
            // if (Number.isInteger(Number(value)) && Number(value) > 0) {
            if (Number(value) > 0) {
                callback();
            } else {
                callback(new Error('请输入正确的应付金额'));
            }
        };
        var check2 = (rule, value, callback) => {
            if (Number(value) > 0) {
                callback();
            } else {
                callback(new Error('请选择被申请方的企业类型'));
            }
        };
        return {
            thisVue: this,
            buttons: [],

            currentUser: {},
            // 实体类名
            entity: 'RequestBill',

            // 地区选择
            areaSelVal: [],
            areaOptions: regionData,

            disabled: false,
            billID: 0, //单据表头ID
            billModel: {},
            rules: {
                OweAmount: [
                    { validator: checkOweAmount, trigger: 'blur' },
                ],
                // OweStartDate:[
                //     { type: 'date', required: true, message: '请选择逾期起始', trigger: 'change' }
                // ],
                ResponseEnterpriseTypeID:[
                    { validator: check2, trigger: 'blur' },
                ],
                ResponseEnterprise: [
                    { required: true, message: '请输入被申请方企业名称', trigger: 'blur' },
                ],
                ResponseLicense: [
                    { required: true, message: '请输入被申请方营业执照', trigger: 'blur' },
                ],
                ResponsePhone: [
                    { required: true, message: '请输入被申请方公司电话', trigger: 'blur' },
                ],
                ResponseContact: [
                    { required: true, message: '请输入被申请人', trigger: 'blur' },
                ],
                ResponseAddress: [
                    { required: true, message: '请输入被申请方公司地址', trigger: 'blur' },
                ],
                Remark: [
                    { required: true, message: '请输入申请理由', trigger: 'blur' },
                ],
            },

            EnterpriseTypeList: [],
            EnterpriseList: [],
            EvidenceList: [],

            showReason: false,
            ReasonStr: ''
        }
    },
    mounted() {
        this.billID = Number(this.$route.query.id)
        if (this.billID === 0) {
            this.onCreate()
        }else{
            this.onGetModel()
        }
    },
    methods: {
        onCreate(){
            this.$API.Create(this.entity, (resp) => {
                this.billModel = resp.Data

                this.onInitArea(this.billModel.ResponseRegionCode)
                this.onSetIdentity()
            })
        },

        onGetModel() {
            this.$API.GetModel(this.entity, this.billID, (resp) => {
                this.billModel = resp.Data

                this.onInitArea(this.billModel.ResponseRegionCode)
                this.onSetIdentity()
            })
        },

        //初始化地区组件需要的值
        onInitArea(regionCode){
            if(this.$Util.IsNull(regionCode.trim()))
                regionCode = "440605"

            let province = regionCode.substr(0, 2)
            let city = regionCode.substr(2, 2)
            this.areaSelVal = [ province + "0000", province + city + "00", regionCode]

            // 地区代码转换中文省市区
            this.onSetAreaToText()
        },

        // 地区组件选择值改变事件
        onAreaChange(arr) {
            this.areaSelVal = arr

            // 地区代码转换中文省市区
            this.onSetAreaToText()
        },

        // 地区代码转换中文省市区
        onSetAreaToText(){
            this.billModel.ResponseProvince = CodeToText[this.areaSelVal[0]]
            this.billModel.ResponseCity = CodeToText[this.areaSelVal[1]]
            this.billModel.ResponseDistrict = CodeToText[this.areaSelVal[2]]
            this.billModel.ResponseRegionCode = this.areaSelVal[2]
        },

        onSetIdentity(){
            let status = this.billModel.Status
            let currentClientID = this.$Util.GetStore("CurrentClientID")
            switch (currentClientID){
                //管理员
                case "Admin":
                    // 企业类型
                    this.$API.GetList('EnterpriseType', {}, (resp) => {
                        this.EnterpriseTypeList = resp.Data.Items
                    })
                    // 企业
                    this.$API.GetList('Enterprise', {}, (resp) => {
                        this.EnterpriseList = resp.Data.Items
                    })
                    this.disabled = true
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    // this.disabled = (!(status === "B"))
                    // this.buttons = [
                    //     { label: '驳回', event: 'onReject', icon: 'el-icon-finished', disabled: this.disabled },
                    // ]
                    break

                //企业机构
                case "Enterprise":
                    // 企业类型
                    this.$API.GetList('EnterpriseType', {}, (resp) => {
                        this.EnterpriseTypeList = resp.Data.Items
                    })
                    // 企业
                    this.$API.GetModel('Enterprise', this.billModel.EnterpriseID, (resp) => {
                        this.EnterpriseList = [resp.Data]
                    })
                    // 存证单
                    this.$API.GetList('EvidenceBill', {}, (resp) => {
                        this.EvidenceList = resp.Data.Items
                    })
                    this.disabled = (!(status === "A" || status === "D"))
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    this.buttons = [
                        { label: '保存', event: 'onSave', icon: 'el-icon-check', disabled: this.disabled },
                        { label: '提交', event: 'onSubmit', icon: 'el-icon-finished', disabled: this.disabled },
                        { label: '撤销', event: 'onRevoke', icon: 'el-icon-refresh-left', disabled: status === 'B' ? false : true }
                    ]
                    break

                //调解机构
                case "Mediator":
                    // 企业类型
                    this.$API.GetList('EnterpriseType', {}, (resp) => {
                        this.EnterpriseTypeList = resp.Data.Items
                    })

                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    this.disabled = (status !== "B")
                    this.disabled = true
                    this.buttons = [
                        { label: '受理', event: 'onAudit', icon: 'el-icon-refresh-left', disabled: status !== "B" },
                        { label: '驳回', event: 'onReject', icon: 'el-icon-finished', disabled: status !== "B" },
                    ]
                    break

                //社会组织
                case "Community":
                    // 企业类型
                    this.$API.GetList('EnterpriseType', {}, (resp) => {
                        this.EnterpriseTypeList = resp.Data.Items
                    })
                    // 企业
                    this.$API.GetList('Enterprise', {}, (resp) => {
                        this.EnterpriseList = resp.Data.Items
                    })
                    this.disabled = (!(status === "A" || status === "D"))
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    this.buttons = [
                        { label: '保存', event: 'onSave', icon: 'el-icon-check', disabled: this.disabled },
                        { label: '提交', event: 'onSubmit', icon: 'el-icon-finished', disabled: this.disabled },
                        { label: '撤销', event: 'onRevoke', icon: 'el-icon-refresh-left', disabled: status === 'B' ? false : true }
                    ]
                    break
            }
        },

        //申请方企业类型
        onSelResponseEnterpriseType(id){
            let array = this.EnterpriseTypeList.filter(item => {
                if(item.ID === id) return true
            })
            this.billModel.ResponseEnterpriseTypeID = array[0].ID
        },
        //申请方企业
        onSelResponseEnterprise(id){
            let array = this.EnterpriseList.filter(item => {
                if(item.ID === id) return true
            })
            this.billModel.EnterpriseID = array[0].ID
            this.billModel.Enterprise = array[0]

            //申请人
            this.billModel.EnterpriseUserID = array[0].AdminUserID
        },
        //存证单
        onSelEvidence(id){
            let array = this.EvidenceList.filter(item => {
                if(item.ID === id) return true
            })
            this.billModel.EvidenceBillID = array[0].ID
            this.billModel.EvidenceBillNo = array[0].Number
        },

        onShowProgress(){
            if(this.billID == 0){
                this.$Util.MsgBox("请先保存单据！", 0)
                return
            }
            this.$refs.BSYProgress.onShowDialog(this.billID)
        },

        onRelevantBill(){
            if(this.billModel.ResponseBillID === 0)
                this.$Util.MsgBox("暂无关联的受理单！", 0)
            //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
            else if(this.billModel.Status === "A" || this.billModel.Status === "B" || this.billModel.Status === "D"){
                this.$Util.MsgBox(this.billModel.StatusName + "不存在受理单！", 0)
            }else
                this.$Util.NavigateTo("/response-detail", { id: this.billModel.ResponseBillID })
        },

        onEvidenceBill(){
            if(this.billModel.EvidenceBillID === 0){
                this.$Util.MsgBox("暂无关联的存证单！", 0)
                return
            }
            this.$Util.NavigateTo("/evidence-detail", { id: this.billModel.EvidenceBillID })
        },

        // 保存
        onSave() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    if(this.billModel.Status === "A" || this.billModel.Status === "D"){
                        this.$API.Save(this.entity, this.billModel, resp => {
                            this.$Util.MsgBox(resp.Message, 1)
                            this.billID = resp.Data.ID

                            this.onGetModel()
                        })
                    }else{
                        this.$Util.MsgBox("【待提交】或【已驳回】状态才允许保存操作！", -1)
                    }
                } else {
                    return false;
                }
            })
        },

        // 提交
        onSubmit() {
            if(this.billID === 0) return

            //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
            if(this.billModel.Status === "A" || this.billModel.Status === "D"){
                this.$API.PostService('RequestBill/Submit', { BillID: this.billID }, resp => {
                    this.$Util.MsgBox(resp.Message, 1)
                    this.onGetModel()
                })
            }else{
                this.$Util.MsgBox("【待提交】或【已驳回】状态才允许提交操作！", -1)
            }
        },

        // 撤销
        onRevoke() {
            if(this.billID === 0) return

            //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
            if(this.billModel.Status === "B"){
                this.$API.PostService('RequestBill/Revoke', { BillID: this.billID }, resp => {
                    this.$Util.MsgBox(resp.Message, 1)
                    this.onGetModel()
                })
            }else{
                this.$Util.MsgBox("【已提交】状态才允许撤销操作！", -1)
            }
        },

        // 受理
        onAudit() {
            if(this.billID === 0) return

            //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
            if(this.billModel.Status !== "B"){
                this.$Util.MsgBox("已提交状态才允许受理操作！", -1)
                return
            }

            this.$API.PostService('RequestBill/Audit', { BillID: this.billID }, resp => {
                this.$Util.MsgBox(resp.Message, 1)
                this.onGetModel()
            })
        },

        // 驳回
        onReject() {
            if(this.billID === 0) return

            //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
            if(this.billModel.Status !== "B"){
                this.$Util.MsgBox("【已提交】状态才允许驳回操作！", -1)
                return
            }

            this.showReason= true
        },

        onConfirmReject(){
            //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
            if(this.$Util.IsNull(this.ReasonStr)){
                this.$Util.MsgBox("请输入驳回说明！", -1)
                return
            }

            this.$API.PostService('RequestBill/Reject', { BillID: this.billID, Reason: this.ReasonStr }, resp => {
                this.showReason = false

                this.$Util.MsgBox(resp.Message, 1)
                this.onGetModel()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-request-detail{
    margin-bottom: 40px;
}

.bsy-bill-card{
    margin-bottom: 5px;
}
</style>
