<template>
    <div>
        <el-row type="flex" justify="end" v-if="buttonVisible">
            <el-col :span="10">
                <div class="_tltle">{{ title }}</div>
            </el-col>
            <el-col :span="14">
                <el-button-group style="width: 100%" >
                    <el-tooltip effect="dark" content="添加节点" placement="bottom">
                        <el-button style="width: 25%" type="primary" icon="el-icon-plus" @click="openNodeDialog(1)" />
                    </el-tooltip>
                    <el-tooltip effect="dark" content="移除节点" placement="bottom">
                        <el-button style="width: 25%" type="primary" icon="el-icon-minus" @click="remove" />
                    </el-tooltip>
                    <el-tooltip effect="dark" content="修改当前节点" placement="bottom">
                        <el-button style="width: 25%" type="primary" icon="el-icon-edit" @click="openNodeDialog(2)" />
                    </el-tooltip>
                    <el-tooltip effect="dark" content="全部展开/折叠" placement="bottom">
                        <el-button style="width: 25%" type="primary" icon="el-icon-d-caret" @click="unFoldAll" />
                    </el-tooltip>
                </el-button-group>
            </el-col>
        </el-row>
        <el-input placeholder="输入关键字进行过滤" v-model="filterText" />
        <el-tree
            :data="treeData"
            :props="treeProps"
            node-key="ID"
            ref="tree"
            :show-checkbox="false"
            :filter-node-method="filterNode"
            :draggable="buttonVisible"
            default-expand-all
            check-on-click-node
            :expand-on-click-node="false"
            @node-click="handleNodeClick"
            @node-drop="handleDrop"
        >
            <!--            <span slot-scope="{ node }">-->
            <!--                <span>{{ node.label }}</span>-->
            <!--            </span>-->
        </el-tree>
        <el-dialog :title="'添加“ '+title+' ”节点'" width="30%" :modal-append-to-body="false" :visible.sync="dialogVisible">
            <ob-form>
                <el-row>
                    <el-form-item label="父级节点" v-if="nodeData.showParent">
                        <el-row>
                            <el-col :span="nodeData.ParentID === 0 ? 24 : 18">
                                <el-input v-model="nodeData.ParentName" type="text" :disabled="nodeData.ParentID === 0 ? true : false"/>
                            </el-col>
                            <el-col :span="nodeData.ParentID === 0 ? 0 : 6">
                                <el-button type="primary" icon="el-icon-s-help" @click="onSetFirstNode">设为根节点</el-button>
                            </el-col>
                        </el-row>

                    </el-form-item>
                    <el-form-item label="分组编码">
                        <el-input v-model="nodeData.Number" type="text"/>
                    </el-form-item>
                    <el-form-item label="分组名称">
                        <el-input v-model="nodeData.Name" type="text"/>
                    </el-form-item>
                    <el-form-item label="绑分销商" v-if="nodeData.ParentID === 0">
                        <el-autocomplete
                                style="width: 100%"
                                clearable
                                v-model="nodeData.Distributor.Name"
                                :fetch-suggestions="querySearchAsync"
                                :trigger-on-focus="true"
                                @select="handleSelect"
                                placeholder="绑定分销商在小程序端显示"
                                :hide-loading="false"
                        >
                        <template slot-scope="{ item }">
                            <div>{{ item.Name }}</div>
                        </template>
                        </el-autocomplete>
                    </el-form-item>
                </el-row>
            </ob-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="onSave">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'ObTree',
    props: {
        title: {
            type: String
        },
        groupType: {
            type: String,
            default: ''
        },
        apiEntry: {
            type: String,
        },
        buttonVisible: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        filterText(val) {
            this.$refs.tree.filter(val)
        }
    },
    data() {
        return {
            //tree的显示
            treeProps: {
                label: 'Label',
                children: 'Children'
            },
            treeData: [],
            nodeData: {
                Distributor:{ID: 0, Name: ""}
            },
            filterText: '',
            expanded: true, // 全部展开
            dialogVisible: false
        }
    },
    methods: {
        //绑定数据源
        binding(data) {
            this.treeData = data
        },

        //查询分组接口
        GetTreeData(entry){
            let param = {
                pEntityType:entry,
                isParts:0              //  （1：配件标识  /  0 :商品标识）
            }
            this.$BizApi.PostMethod('Group', 'GetTree', param, (resp) => {
                this.treeData = resp.Data
            })

        },

        //过滤
        filterNode(value, data) {
            if (!value) return true
            return data.Name.indexOf(value) !== -1
        },

        openNodeDialog(type) {
            let clickNode = this.$refs.tree.getCurrentNode()
            this.nodeData = clickNode

            switch (type) {
                case 1://新增
                    this.nodeData = {
                        ParentID: clickNode === null ? 0 : clickNode.ID,
                        ParentName: clickNode === null ? "根节点" : clickNode.Name,
                        showParent: true,
                        EntityType: this.groupType,
                        Number: '',
                        Name: '',
                        Distributor: clickNode === null ? { ID: 0, Name: "" } : clickNode.Distributor
                    }
                    break

                case 2://修改
                    if (this.$Util.IsNull(clickNode)) {
                        this.$Util.MsgBox('请先选择需要修改的节点',-1)
                        return
                    }
                    this.nodeData.ParentID = clickNode.ParentID
                    this.nodeData.Number = clickNode.Number
                    this.nodeData.Name = clickNode.Name

                    if(clickNode.Distributor)
                        this.nodeData.Distributor = clickNode.Distributor
                    else
                        this.nodeData.Distributor = { ID: 0, Name: "" }
                    break
            }
            this.dialogVisible = true
        },

        //确定保存
        onSave() {
            if(this.$Util.IsNull(this.nodeData.Name)){
                this.$Util.MsgBox('分组名称不能为空', -1)
                return
            }

            this.dialogVisible = false

            //分销商
            if(this.$Util.IsNull(this.nodeData.Distributor)){
                this.nodeData.Distributor = {ID: 0}
            }

            this.$BizApi.SaveModel(this.apiEntry, this.nodeData, () => {
                    this.$emit('refresh')
                }
            )
        },

        //移除节点
        remove() {
            let clickNode = this.$refs.tree.getCurrentNode()
            if (clickNode === null) {
                this.$Util.MsgBox('请先选择需要删除的节点', 0)
                return
            } else {
                this.$confirm('是否删除该节点' + clickNode.Name + '?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$BizApi.RemoveModel(this.apiEntry,clickNode.ID,() => {
                            this.$emit('refresh')
                        }
                    )
                })
            }
        },

        handleNodeClick(data) {
            this.$emit('onClickNode',data)
        },

        //拖拽节点
        handleDrop(draggingNode, dropNode) {
            let childrenObj = draggingNode.data

            this.$confirm('是否保存拖拽至当前节点：' + dropNode.data.Name + '?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let parentId = dropNode.data.ID

                childrenObj.ParentID = parentId
                this.nodeData = childrenObj
                this.onSave()
            }).catch(()=>{
                this.$emit('refresh')
            })
        },

        // 全部展开/折叠
        unFoldAll() {
            let that = this
            that.expanded = !that.expanded

            let treeList = that.treeData
            for (let i = 0; i < treeList.length; i++) {
                // 将没有转换成树的原数据设置key为... 的展开
                that.$refs.tree.store.nodesMap[treeList[i].ID].expanded = that.expanded
            }
        },

        //el-autocomplete搜索🔍
        querySearchAsync(queryString, cb) {
            let that = this
            let filter = ''
            if(!this.$Util.IsNull(queryString)){
                filter = ' Name.Contains("' + queryString + '") || Number.Contains("' + queryString + '")'
            }

            let param = {
                FilterStr: filter,
                Filter: [{}],
                Page: 1,
                Size: 50,
                keyword: '',
                Sort: ''
            }
            that.$BizApi.PostMethod("Distributor", "GetListEx", param, (resp) => {
                    cb(resp.Data) // 调用 callback 返回建议列表的数据
                },
            )
        },


        btnClick() {
            console.log('btnClick')
        },

        handleSelect(data) {
            console.log('handleSelect', data)
            this.nodeData.DistributorID = data.ID
            this.nodeData.Distributor = data
        },

        //设置根节点
        onSetFirstNode(){
            this.nodeData.ParentID = 0
            this.nodeData.ParentName = '根节点'
        }
    }
}
</script>

<style scoped>
._tltle {
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
}
</style>
