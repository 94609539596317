<template>
    <div class="page-response-detail">
        <div style="padding: 5px">
            <el-tabs v-model="activeName" style="background-color: white;padding-left: 10px">
                <el-tab-pane label="案件信息" name="first">
                    <el-form :model="billModel" :rules="rules" ref="ruleForm" label-position="right" label-width="100px" :disabled="disabled">
                        <el-card class="bsy-bill-card">
                            <div slot="header">
                                <span>基本信息</span>
                                <el-button-group>
                                    <el-button type="primary" style="padding: 3px;margin-left: 20px" @click="onShowProgress" :disabled="false">进度查询</el-button>
                                    <el-button type="primary" style="padding: 3px;" @click="onRelevantBill" :disabled="false">查看申请单</el-button>
                                </el-button-group>
                            </div>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="单据状态" prop="StatusName">
                                        <el-input disabled v-model="billModel.StatusName" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="受理单号">
                                        <el-input disabled v-model="billModel.Number" placeholder="保存后自动生成" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="业务日期" prop="BizDateText">
                                        <el-input disabled v-model="billModel.BizDateText" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="申请单类型">
                                        <el-input disabled v-model="billModel.RequestBillType.Name" />
                                        <!--                            <el-select v-model="billModel.RequestBillTypeID" style="width: 100%">-->
                                        <!--                                <div v-for="(item,index) in RequestTypeList" :key="index">-->
                                        <!--                                    <el-option :label="item.Name" :value="item.ID" />-->
                                        <!--                                </div>-->
                                        <!--                            </el-select>-->
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="创建时间">
                                        <el-input disabled v-model="billModel.CreatedOn" placeholder="保存后自动生成" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="创建人">
                                        <el-input disabled v-model="billModel.CreatedBy" placeholder="保存后自动生成" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="修改时间">
                                        <el-input disabled v-model="billModel.ModifiedOn" placeholder="保存后自动生成" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="修改人">
                                        <el-input disabled v-model="billModel.ModifiedBy" placeholder="保存后自动生成" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-card>

                        <el-card class="bsy-bill-card">
                            <div slot="header">
                                <span>申请方</span>
                            </div>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="申请企业">
                                        <el-input disabled v-model="billModel.Enterprise.Name" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="申请人">
                                        <el-input disabled v-model="billModel.EnterpriseUser.Name" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="提交人">
                                        <el-input disabled v-model="billModel.SubmittedBy" placeholder="保存后自动生成" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="提交时间">
                                        <el-input disabled v-model="billModel.SubmittedOn" placeholder="保存后自动生成" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-card>

                        <el-card class="bsy-bill-card">
                            <div slot="header">
                                <span>被申请方</span>
                            </div>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="企业名称" prop="ResponseEnterprise">
                                        <el-input disabled v-model="billModel.ResponseEnterprise" placeholder="请输入被申请方企业名称" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="企业类型">
                                        <el-input disabled v-model="billModel.ResponseEnterpriseType.Name" />
                                        <!--                            <el-select v-model="billModel.ResponseEnterpriseTypeID" placeholder="请选择被申请方企业类型" @change="onSelResponseEnterpriseType" style="width: 100%" >-->
                                        <!--                                <div v-for="(item,index) in EnterpriseTypeList" :key="index">-->
                                        <!--                                    <el-option :label="item.Name" :value="item.ID" />-->
                                        <!--                                </div>-->
                                        <!--                            </el-select>-->
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="营业执照" prop="ResponseLicense">
                                        <el-input disabled v-model="billModel.ResponseLicense" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="公司电话" prop="ResponsePhone">
                                        <el-input disabled v-model="billModel.ResponsePhone" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="所在省份">
                                        <el-input disabled v-model="billModel.ResponseProvince" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="所在城市">
                                        <el-input disabled v-model="billModel.ResponseCity" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="所在地区">
                                        <el-input disabled v-model="billModel.ResponseDistrict" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="公司地址">
                                        <el-input disabled v-model="billModel.ResponseAddress" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-card>

                        <el-card class="bsy-bill-card">
                            <div slot="header">
                                <span>调解机构</span>
                            </div>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="调解机构">
                                        <el-input disabled v-model="billModel.MediatorName" placeholder="受理后自动生成" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="调解机构电话">
                                        <el-input disabled v-model="billModel.MediatorPhone" placeholder="受理后自动生成" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="调解员">
                                        <el-input disabled v-model="billModel.MediatorUserName" placeholder="受理后自动生成" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="调解员电话">
                                        <el-input disabled v-model="billModel.MediatorUserPhone" placeholder="受理后自动生成"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="调解开始日期">
                                        <el-input disabled v-model="billModel.MediateStartDate" placeholder="受理后自动生成"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="调解结束">
                                        <el-input disabled v-model="billModel.MediateEndDate" placeholder="驳回后自动生成"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="调解天数">
                                        <el-input disabled v-model="billModel.MediateDays" placeholder="驳回后自动生成"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row v-if="!$Util.IsNull(billModel.RejectedOn)">
                                <el-col :span="6">
                                    <el-form-item label="驳回时间">
                                        <el-input disabled v-model="billModel.RejectedOn" placeholder="驳回后自动生成"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="驳回人">
                                        <el-input disabled v-model="billModel.RejectedBy" placeholder="驳回后自动生成"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="驳回原因">
                                        <el-input disabled v-model="billModel.RejectReason" placeholder="驳回后自动生成"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-card>

<!--                        <el-card class="bsy-bill-card">-->
<!--                            <div slot="header">-->
<!--                                <span>调解机构</span>-->
<!--                            </div>-->
<!--                            <el-row>-->
<!--                                <el-col :span="6">-->
<!--                                    <el-form-item label="调解机构">-->
<!--                                        <el-input v-model="billModel.MediatorName" placeholder="受理后自动生成" />-->
<!--                                    </el-form-item>-->
<!--                                </el-col>-->
<!--                                <el-col :span="6">-->
<!--                                    <el-form-item label="调解员">-->
<!--                                        <el-input v-model="billModel.MediatorUserName" placeholder="受理后自动生成" />-->
<!--                                    </el-form-item>-->
<!--                                </el-col>-->
<!--                                <el-col :span="6">-->
<!--                                    <el-form-item label="调解机构电话">-->
<!--                                        <el-input v-model="billModel.MediatorPhone" placeholder="受理后自动生成" />-->
<!--                                    </el-form-item>-->
<!--                                </el-col>-->
<!--                                <el-col :span="6">-->
<!--                                    <el-form-item label="调解员电话">-->
<!--                                        <el-input v-model="billModel.MediatorUserPhone" placeholder="受理后自动生成"/>-->
<!--                                    </el-form-item>-->
<!--                                </el-col>-->
<!--                            </el-row>-->
<!--                            <el-row>-->
<!--                                <el-col :span="6">-->
<!--                                    <el-form-item label="调解开始日期">-->
<!--                                        <el-input readonly v-model="billModel.MediateStartDate" placeholder="受理后自动生成"/>-->
<!--                                    </el-form-item>-->
<!--                                </el-col>-->
<!--                                <el-col :span="6">-->
<!--                                    <el-form-item label="驳回时间">-->
<!--                                        <el-input readonly v-model="billModel.RejectedOn" placeholder="驳回后自动生成"/>-->
<!--                                    </el-form-item>-->
<!--                                </el-col>-->
<!--                                <el-col :span="6">-->
<!--                                    <el-form-item label="驳回人">-->
<!--                                        <el-input readonly v-model="billModel.RejectedBy" placeholder="驳回后自动生成"/>-->
<!--                                    </el-form-item>-->
<!--                                </el-col>-->
<!--                                <el-col :span="6">-->
<!--                                    <el-form-item label="驳回原因">-->
<!--                                        <el-input readonly v-model="billModel.RejectReason" placeholder="驳回后自动生成"/>-->
<!--                                    </el-form-item>-->
<!--                                </el-col>-->
<!--                            </el-row>-->
<!--                        </el-card>-->

                        <el-card class="bsy-bill-card">
                            <div slot="header">
                                <span>代理组织</span>
                            </div>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="代理组织">
                                        <el-input readonly v-model="billModel.CommunityName" placeholder="保存后自动生成" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="组织电话">
                                        <el-input readonly v-model="billModel.CommunityPhone" placeholder="保存后自动生成" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="代理人">
                                        <el-input readonly v-model="billModel.CommunityUserName" placeholder="保存后自动生成" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="代理电话">
                                        <el-input readonly v-model="billModel.CommunityUserPhone" placeholder="保存后自动生成"/>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-card>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="还款情况" name="second">
                    <el-form>
                        <el-card class="bsy-bill-card">
                            <div slot="header">
                                <span>还款详情</span>
                            </div>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="付款状态">
                                        <el-input disabled v-model="billModel.PayStatusName" placeholder="驳回后自动生成"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="付款比例">
                                        <el-input disabled v-model="billModel.PayRate" placeholder="驳回后自动生成"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="应付金额" prop="OweAmount">
                                        <el-input disabled v-model="billModel.OweAmount" type="number" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="承诺金额">
                                        <el-input disabled v-model="billModel.PromiseAmount" placeholder="受理后自动生成" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="已付金额">
                                        <el-input disabled v-model="billModel.PaidAmount" placeholder="受理后自动生成"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="未付金额">
                                        <el-input disabled v-model="billModel.RemainAmount" placeholder="受理后自动生成"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="逾期起始" prop="OweStartDate">
                                        <el-date-picker disabled v-model="billModel.OweStartDate" type="date" placeholder="请选择逾期起始" style="width: 100%"/>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="逾期结束">
                                        <el-input disabled v-model="billModel.OweEndDate" placeholder="结案后自动生成" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item label="超期天数">
                                        <el-input disabled v-model="billModel.OweDays" placeholder="受理后自动生成" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-button-group>
                                <el-button type="primary" icon="el-icon-circle-plus-outline" :disabled="disabled" @click="onAddRow">新增行</el-button>
                                <el-button type="primary" icon="el-icon-remove-outline" :disabled="disabled" @click="onDelRow">删除行</el-button>
                            </el-button-group>
                            <el-table ref="multipleTable" border :data="billModel.ResponseBillEntries" @selection-change="handleSelectionChange" style="width: 100%;margin-bottom: 20px">
                                <el-table-column label="序号" type="index" width="55"/>
                                <el-table-column label="勾选" type="selection" width="55"/>
                                <el-table-column label="承诺时间">
                                    <template slot-scope="scope">
                                        <el-date-picker :disabled="disabled" v-model="scope.row.PromiseDate" value-format="yyyy-MM-dd" type="date" style="width: 100%"/>
                                    </template>
                                </el-table-column>
                                <el-table-column label="承诺金额">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.PromiseAmount" :disabled="disabled" />
                                    </template>
                                </el-table-column>
                                <el-table-column label="还款时间">
                                    <template slot-scope="scope">
                                        <el-date-picker :disabled="disabled" v-model="scope.row.PaidDate" type="date" value-format="yyyy-MM-dd" style="width: 100%"/>
                                    </template>
                                </el-table-column>
                                <el-table-column label="已付金额">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.PaidAmount" :disabled="disabled" />
                                    </template>
                                </el-table-column>
                                <el-table-column label="备注">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.Note" :disabled="disabled" />
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-card>
                            <el-card class="bsy-bill-card">
                                <div slot="header">
                                    <span>调解日志</span>
                                </div>
                            <el-button-group>
                                <el-button type="primary" icon="el-icon-circle-plus-outline" :disabled="disabledLog" @click="onAddRowLog">新增行</el-button>
                                <el-button type="primary" icon="el-icon-remove-outline" :disabled="disabledLog" @click="onDelRowLog">删除行</el-button>
                            </el-button-group>
                            <el-table ref="multipleTable" border :data="billModel.ResponseBillLogEntries" @selection-change="onSelChangeLog" style="width: 100%;margin-bottom: 20px">
                                <el-table-column label="序号" type="index" width="55"/>
                                <el-table-column label="勾选" type="selection" width="55"/>
                                <el-table-column label="调解时间">
                                    <template slot-scope="scope">
                                        <el-date-picker :disabled="disabledLog" v-model="scope.row.MediateDate" type="date" style="width: 100%"/>
                                    </template>
                                </el-table-column>
                                <el-table-column label="调解事项">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.MediateTask" :disabled="disabledLog" />
                                    </template>
                                </el-table-column>
                                <el-table-column label="调解结果">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.MediateResult" :disabled="disabledLog" />
                                    </template>
                                </el-table-column>
                                <el-table-column label="备注">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.Note" :disabled="disabledLog" />
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-card>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>

        <bsy-buttons ref="BSYButtons" :buttons="buttons" :parentVue="thisVue" />
        <bsy-progress ref="BSYProgress" />

        <el-dialog title="驳回" :visible.sync="showReason" width="30%" center>
            <el-input v-model="ReasonStr" type="textarea" :rows="3" placeholder="请输入驳回说明"/>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showReason = false">取 消</el-button>
                <el-button type="primary" @click="onConfirmReject">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BsyButtons from "../../components/bsy-ui/bsy-buttons";
import BsyProgress from "../../components/bsy-ui/bsy-progress";
import moment from 'moment/moment'

export default {
    name: 'response-detail',
    components: {BsyProgress, BsyButtons},
    computed: {
        ...mapGetters(['USER', 'collapsed']),
    },
    data() {
        var checkOweAmount = (rule, value, callback) => {
            if (Number.isInteger(Number(value)) && Number(value) > 0) {
                callback();
            } else {
                callback(new Error('请输入应付金额'));
            }
        };
        var check2 = (rule, value, callback) => {
            if (Number(value) > 0) {
                callback();
            } else {
                callback(new Error('请选择被申请方的企业类型'));
            }
        };
        return {
            thisVue: this,
            buttons: [],

            currentUser: {},
            // 实体类名
            entity: 'ResponseBill',

            activeName: 'first',
            disabledLog: true,
            disabled: false,
            billID: 0, //单据表头ID
            billModel: {},
            rules: {
                OweAmount: [
                    { validator: checkOweAmount, trigger: 'blur' },
                ],
                // OweStartDate:[
                //     { type: 'date', required: true, message: '请选择逾期起始', trigger: 'change' }
                // ],
                ResponseEnterpriseTypeID:[
                    { validator: check2, trigger: 'blur' },
                ],
                ResponseEnterprise: [
                    { required: true, message: '请输入被申请方企业名称', trigger: 'blur' },
                ],
                ResponseLicense: [
                    { required: true, message: '请输入被申请方营业执照', trigger: 'blur' },
                ],
                ResponsePhone: [
                    { required: true, message: '请输入被申请方公司电话', trigger: 'blur' },
                ],
            },

            // RequestTypeList: [],//申请单类型
            // EnterpriseTypeList: [],

            showReason: false,
            ReasonStr: '',
            multipleSelection: []
        }
    },
    mounted() {
        this.billID = Number(this.$Util.IsNull(this.$route.query.id) ? 0 : this.$route.query.id)
        if(this.billID===0){
            this.$Util.MsgBox("受理单内码异常！", -1)
            return
        }

        this.onGetModel()
    },
    methods: {
        onGetModel() {
            this.$API.GetModel(this.entity, this.billID, (resp) => {
                this.billModel = resp.Data

                this.onSetIdentity()
            })
        },

        onSetIdentity(){
            let status = this.billModel.Status
            let currentClientID = this.$Util.GetStore("CurrentClientID")
            switch (currentClientID){
                //管理员
                case "Admin":
                    this.disabled = true
                    // // 申请方类型
                    // this.$API.GetList('SysEnum', {}, (resp) => {
                    //     this.RequestTypeList = resp.Data.Items
                    // })

                    // 企业类型
                    this.$API.GetList('EnterpriseType', {}, (resp) => {
                        this.EnterpriseTypeList = resp.Data.Items
                    })
                    break

                //企业机构
                case "Enterprise":
                    // // 申请方类型
                    // this.$API.GetList('SysEnum', {}, (resp) => {
                    //     this.RequestTypeList = resp.Data.Items
                    // })

                    // 企业类型
                    this.$API.GetList('EnterpriseType', {}, (resp) => {
                        this.EnterpriseTypeList = resp.Data.Items
                    })
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    this.disabled = true
                    this.buttons = []
                    break

                //调解机构
                case "Mediator":
                    // // 申请方类型
                    // this.$API.GetList('SysEnum', {}, (resp) => {
                    //     this.RequestTypeList = resp.Data.Items
                    // })

                    // 企业类型
                    this.$API.GetList('EnterpriseType', {}, (resp) => {
                        this.EnterpriseTypeList = resp.Data.Items
                    })
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    this.disabledLog = (status === "F" || status === "G")
                    this.disabled = (status === "F" || status === "G")
                    this.buttons = [
                        { label: '履约告知', event: 'onInform', icon: 'el-icon-message', disabled: this.disabled },
                        { label: '确认保存', event: 'onSave', icon: 'el-icon-circle-check', disabled: this.disabled },
                        { label: '手动关闭', event: 'onAbort', icon: 'el-icon-folder-checked', disabled: this.disabled },
                    ]
                    break

                //社会组织
                case "Community":
                    // // 申请方类型
                    // this.$API.GetList('SysEnum', {}, (resp) => {
                    //     this.RequestTypeList = resp.Data.Items
                    // })

                    // 企业类型
                    this.$API.GetList('EnterpriseType', {}, (resp) => {
                        this.EnterpriseTypeList = resp.Data.Items
                    })
                    this.disabled = (!(status === "A" || status === "D"))
                    //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                    this.buttons = [
                        { label: '保存', event: 'onSave', icon: 'el-icon-check', disabled: this.disabled },
                        { label: '提交', event: 'onSubmit', icon: 'el-icon-finished', disabled: this.disabled }
                    ]
                    break
            }
        },

        onShowProgress(){
            this.$refs.BSYProgress.onShowDialog(this.billModel.RequestBillID)
        },

        onRelevantBill(){
            this.$Util.NavigateTo("/request-detail", { id: this.billModel.RequestBillID })
        },
        handleSelectionChange(val){
            this.multipleSelection = val;
        },

        // 新增行
        onAddRow(){
            let newObj = {
                ID: 0,
                Seq: this.billModel.ResponseBillEntries.length + 1,
                MediatorID: this.billModel.MediatorID,
                MediatorUserID: this.billModel.MediatorUserID,
                PromiseDate:  moment(new Date).format('YYYY-MM-DD'),//承诺时间
                PromiseAmount: 0,//承诺金额
                PaidDate:  moment(new Date).format('YYYY-MM-DD'),//还款时间
                PaidAmount: 0,//已付金额
                Note: '',//备注
            }
            this.billModel.ResponseBillEntries.push(newObj)
        },

        // 删除行
        onDelRow(){
            if(this.billModel.ResponseBillEntries.length === 0) {
                this.$Util.MsgBox("请新增行数据！", 0)
                return
            }

            if(this.multipleSelection.length === 0) {
                this.$Util.MsgBox("请勾选需要删除的行数据！", -1)
                return
            }
            this.multipleSelection.forEach((c) => {
                //遍历源数据
                this.billModel.ResponseBillEntries.forEach((v, i) => {
                    if (c.Seq === v.Seq) {
                        this.billModel.ResponseBillEntries.splice(i, 1)
                    }
                })
            })
        },

        // 新增行
        onAddRowLog(){
            let newObj = {
                ID: 0,
                Seq: this.billModel.ResponseBillLogEntries.length + 1,
                MediateDate:  moment(new Date).format('YYYY-MM-DD'),//承诺时间
                MediateResult: "",
                MediateTask: "",
                Note: '',//备注
            }
            this.billModel.ResponseBillLogEntries.push(newObj)
        },

        // 删除行
        onDelRowLog(){
            if(this.billModel.ResponseBillLogEntries.length === 0) {
                this.$Util.MsgBox("请新增行数据！", 0)
                return
            }

            if(this.multipleSelection.length === 0) {
                this.$Util.MsgBox("请勾选需要删除的行数据！", -1)
                return
            }
            this.multipleSelection.forEach((c) => {
                //遍历源数据
                this.billModel.ResponseBillLogEntries.forEach((v, i) => {
                    if (c.Seq === v.Seq) {
                        this.billModel.ResponseBillLogEntries.splice(i, 1)
                    }
                })
            })
        },

        // 保存
        onSave() {
            //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
            if(this.billModel.Status === "E"){
                this.$API.Save(this.entity, this.billModel, resp => {
                    this.$Util.MsgBox(resp.Message, 1)

                    this.onGetModel()
                })
            }else{
                this.$Util.MsgBox("【已告知】状态才允许保存操作！", -1)
            }
        },

        // 告知
        onInform() {
            //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
            if(this.billModel.Status === "C"){
                this.$API.PostService('ResponseBill/Inform', { BillID: this.billID }, resp => {
                    this.$Util.MsgBox(resp.Message, 1)
                    this.onGetModel()
                })
            }else{
                this.$Util.MsgBox("【已受理】状态才允许告知操作！", -1)
            }
        },
        // 手动关闭
        onAbort() {
            //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
            if(this.billModel.Status === "E"){
                this.$alert('是否确定执行手动关闭？', '提示', {
                    confirmButtonText: '确定',
                    showClose: true,
                    callback: () => {
                        this.$API.PostService('ResponseBill/Abort', { BillID: this.billID }, resp => {
                            this.$Util.MsgBox(resp.Message, 1)
                            this.onGetModel()
                        })
                    }
                })
            }else{
                this.$Util.MsgBox("【已告知】状态才允许手动关闭操作！", -1)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.page-response-detail{
    margin-bottom: 40px;

    .bsy-bill-card{
        margin-bottom: 5px;
    }
}
</style>
