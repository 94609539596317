import { render, staticRenderFns } from "./evidence-detail.vue?vue&type=template&id=04d292ce&scoped=true&"
import script from "./evidence-detail.vue?vue&type=script&lang=js&"
export * from "./evidence-detail.vue?vue&type=script&lang=js&"
import style0 from "./evidence-detail.vue?vue&type=style&index=0&id=04d292ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04d292ce",
  null
  
)

export default component.exports