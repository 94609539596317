<template>
    <el-dialog title="进度明细" center :close-on-click-modal="false" :visible.sync="showDialog" v-loading="loading" >
        <el-table :data="listData" border style="width: 100%">
            <el-table-column label="单据状态" prop="StatusName" />
            <el-table-column label="付款状态" prop="PayStatusName" />
            <el-table-column label="操作时间" prop="ProgressTime" />
            <el-table-column label="操作用户" prop="ProgressUser" />
            <el-table-column label="操作说明" prop="ProgressDescription" />
<!--            <el-table-column-->
<!--                prop="ProgressDescription"-->
<!--                label="操作说明"-->
<!--                width="180">-->
<!--            </el-table-column>-->
        </el-table>
    </el-dialog>
</template>

<script>

export default {
    name: "bsy-progress",
    props: {

    },
    data(){
      return{
          showDialog: false,
          loading: false,
          listData: [],
      }
    },
    mounted() {

    },
    methods: {
        onShowDialog(id) {
            this.loading = true
            this.showDialog = true
            this.$API.PostService("RequestBill/Progress", { RequestBillID: id }, resp => {
                this.listData = resp.Data
                this.loading = false
            })
        },

        //设置加载状态
        onSetLoading(val){
            this.loading = val
        }
    }
}
</script>

<style lang="scss" scoped>
.bsy-float-button{
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 40px;
    background: #eeeeee;
    box-shadow: 0 0 4px 0 #999999;
    border-radius: 2px;
    z-index: 10;
}
</style>
