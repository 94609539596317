<template>
    <el-autocomplete class="top-search" popper-class="my-autocomplete" v-model="value" :fetch-suggestions="querySearch" placeholder="搜索" @select="handleSelect">
        <template slot-scope="{ item }">
            <i :class="[item[iconKey], 'icon']"></i>
            <div class="name">{{ item[labelKey] }}</div>
            <!--<div class="addr">{{ item[pathKey] }}</div>-->
        </template>
    </el-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'nav-search',
    data() {
        return {
            value: '',
            menuList: []
        }
    },
    created() {
        this.getMenuList()
    },

    watch: {
        menu() {
            this.getMenuList()
        }
    },
    computed: {
        labelKey() {
            return 'Title'
        },
        pathKey() {
            return 'Path'
        },
        iconKey() {
            return 'Icon'
        },
        childrenKey() {
            return 'Children'
        },
        ...mapGetters(['MenuAll'])
    },
    methods: {
        getMenuList() {
            const findMenu = (list) => {
                if (list === undefined) return
                for (let i = 0; i < list.length; i++) {
                    const ele = Object.assign({}, list[i])
                    if (this.$Util.IsNull(ele[this.childrenKey])) {
                        this.menuList.push(ele)
                    } else {
                        findMenu(ele[this.childrenKey])
                    }
                }
            }
            this.menuList = []
            findMenu(this.MenuAll)
        },
        querySearch(queryString, cb) {
            let restaurants = this.menuList
            let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
            // 调用 callback 返回建议列表的数据
            cb(results)
        },
        createFilter(queryString) {
            return (data) => {
                return data[this.labelKey].toLowerCase().indexOf(queryString.toLowerCase()) === 0
            }
        },
        handleSelect(item) {
            this.value = ''
            this.$router.push({
                path: this.$router.$dynamicRouter.getPath(
                    {
                        name: item[this.labelKey],
                        src: item[this.pathKey]
                    },
                    item.meta
                ),
                query: item.query
            })
        }
    }
}
</script>

<style lang="scss">
.my-autocomplete {
    li {
        line-height: normal;
        padding: 7px;
        .icon {
            margin-right: 5px;
            display: inline-block;
            vertical-align: middle;
        }
        .name {
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            vertical-align: middle;
        }
        .addr {
            padding-top: 5px;
            width: 100%;
            font-size: 12px;
            color: #b4b4b4;
        }

        .highlighted .addr {
            color: #ddd;
        }
    }
}
</style>
