<template>
    <split-pane split="vertical" :min-percent="20" :max-percent="30" :default-percent="displayGroup ? 20 : 0">
        <template slot="paneL" v-if="displayGroup">
            <app-container>
                <ob-tree :groupType="entity" apiEntry="Group" :title="title + '分组'" :buttonVisible="displayGroupButton" ref="obTree" @onClickNode="onClickTreeNode" @refresh="initGroup" />
            </app-container>
        </template>
        <template slot="paneR">
            <app-container>
                <el-row>
                    <el-col :span="6">
                        <el-input id="input" type="text" size="mini" v-model="filterStr" placeholder="编码、名称" @change="RefreshData" clearable>
                            <template slot="prepend">
                                <el-select v-model="selectStr" placeholder="全部状态" style="width: 100px">
                                    <el-option label="全部状态" value=""></el-option>
                                    <el-option label="创建" value="0"></el-option>
                                    <el-option label="审核中" value="1"></el-option>
                                    <el-option label="已审核" value="2"></el-option>
                                    <el-option label="重新审核" value="3"></el-option>
                                    <el-option label="已禁用" value="4"></el-option>
                                </el-select>
                            </template>
                        </el-input>
                    </el-col>
                    <el-col :span="18">
                        <div style="margin-left: 10px">
                            <ob-buttons :buttons="buttonsGroup" :parentVue="thisVue" />
                        </div>
                    </el-col>
                </el-row>
                <ob-grid
                    :page-size="15"
                    ref="obGrid"
                    :gridHeight="gridHeight"
                    :columns="cloumnsArr"
                    :multi-select="false"
                    :filter-row="true"
                    :paging="true"
                    @rowClick="onRowClick"
                    @rowDblClick="onRowDblClick"
                    @refreshGrid="RefreshData"
                />
            </app-container>
            <ob-base-modal ref="obBase" />
        </template>
    </split-pane>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'templateList',
    props: {
        //标题
        title: {
            type: String,
            default: ''
        },
        //是否显示分组
        displayGroup: {
            type: Boolean,
            default: false
        },
        displayGroupButton: {
            type: Boolean,
            default: false
        },
        //实体类名
        entity: {
            type: String,
            default: ''
        },
        //Post方法
        method: {
            type: String,
            default: ''
        },
        //单据操作按钮集合
        buttonsGroup: {
            type: Array,
            default: () => [
                { label: '查询', event: 'RefreshData', icon: 'el-icon-search', disabled: false },
                { label: '新增', event: 'CreateData', icon: 'el-icon-circle-plus-outline', disabled: false },
                { label: '删除', event: 'RemoveData', icon: 'el-icon-remove-outline', disabled: false },
                { label: '提交', event: '', icon: 'el-icon-open', disabled: false },
                { label: '撤销', event: '', icon: 'el-icon-turn-off', disabled: false },
                { label: '审核', event: '', icon: 'el-icon-open', disabled: false },
                { label: '反审', event: '', icon: 'el-icon-turn-off', disabled: false }
                // { label: '启用', event: '', icon: 'el-icon-open', disabled: false },
                // { label: '禁用', event: '', icon: 'el-icon-turn-off', disabled: false },
            ]
        },
        //表格数据自定义列
        tableColumns: {
            type: Array,
            default: null
        },
        //对应详情路径
        detailPath: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters(['tag']),
        gridHeight: function() {
            let rstHeight = this.winHeight - 130
            return rstHeight
        }
    },
    data() {
        return {
            thisVue: this, //ob-buttons parentVue
            winHeight: 0, //窗体的高度
            cloumnsArr: [], // 列表

            clickGroupID: 0, //点击了分组的ID
            filterStr: '', //快捷查询的文本框
            selectStr: '', //快捷查询的下拉选择框
            checkData: {} //获取点击行数据
        }
    },
    mounted() {
        //获取窗体的高度
        this.winHeight = window.innerHeight
        let that = this
        window.onresize = () => {
            return (() => {
                that.winHeight = window.innerHeight
            })()
        }

        this.initTableCloumns()

        //设置input自动聚焦
        let inputelement = document.getElementById('input')
        inputelement.focus()

        if (this.displayGroup) {
            this.initGroup()
        }
        this.RefreshData()
    },

    methods: {
        //固定列字段
        initTableCloumns() {
            this.cloumnsArr = [
                {
                    caption: this.title + '编码',
                    dataField: 'Number',
                    dataType: 'string',
                    fixed: true,
                    fixedPosition: 'left',
                    alignment: 'left',
                    allowSorting: true,
                    allowFiltering: true
                },
                {
                    caption: this.title + '名称',
                    dataField: 'Name',
                    dataType: 'string',
                    fixed: true,
                    fixedPosition: 'left',
                    alignment: 'left',
                    allowSorting: true,
                    allowFiltering: true
                },
				{
				    // width: 80,
				    caption: '价格',
				    dataField: 'Price',
				    dataType: 'string',
				    fixed: true,
				    fixedPosition: 'left',
				    alignment: 'left',
				    allowSorting: true,
				    allowFiltering: true,
					},
                {
                    // width: 80,
                    caption: '单据状态',
                    dataField: 'StatusName',
                    dataType: 'string',
                    fixed: true,
                    fixedPosition: 'left',
                    alignment: 'left',
                    allowSorting: true,
                    allowFiltering: true,
					},

                {
                    width: 100,
                    caption: '创建人',
                    dataField: 'CreatedBy',
                    dataType: 'string',
                    fixed: false, //不固定列，相对靠后
                    fixedPosition: 'left',
                    alignment: 'left',
                    allowSorting: true,
                    allowFiltering: true
                },
                {
                    width: 100,
                    caption: '审核人',
                    dataField: 'CheckedBy',
                    dataType: 'string',
                    fixed: false, //不固定列，相对靠后
                    fixedPosition: 'left',
                    alignment: 'left',
                    allowSorting: true,
                    allowFiltering: true
                }
            ]

            //添加自定义字段
            if (!this.$Util.IsNull(this.tableColumns)) {
                this.cloumnsArr = this.cloumnsArr.concat(this.tableColumns)
            }
        },

        initGroup() {
            this.$refs.obTree.GetTreeData(this.entity)
        },

        // 查询按钮 - 获取接口数据
        RefreshData() {
            let filter = ''
            //文本框
            if (!this.$Util.IsNull(this.filterStr)) {
                filter = ' Number.Contains("' + this.filterStr + '") || Name.Contains("' + this.filterStr + '") '
                //下拉选择框
                if (!this.$Util.IsNull(this.selectStr)) {
                    filter += ' && Status="' + this.selectStr + '"'
                }
            } else {
                //下拉选择框
                if (!this.$Util.IsNull(this.selectStr)) {
                    filter = ' Status="' + this.selectStr + '"'
                }
            }

            // entity, method, filter, sort, lib, key, url
            this.$refs.obGrid.SetData(this.entity, this.method, filter, '')
        },

        //点击分组关联过滤表格数据
        onClickTreeNode(data) {
            let parentId = data.ID
            let filter = 'GroupID=' + parentId
            this.clickGroupID = parentId
            // entity, filter, sort, lib, method, key, url
            this.$refs.obGrid.SetData(this.entity, filter, '', '', this.method)
        },

        //单击行获取到的数据
        onRowClick(data) {
            this.checkData = data
        },

        //双击表单行打开详情
        onRowDblClick(data) {
            let thisid = data.ID
            let thisdisabled = data.Status === 1 ? 0 : 1
            this.$Util.NavigateTo(this.detailPath, { id: thisid, disabled: thisdisabled })
        },

        //退出当前页面
        ClosePage() {
            this.$store.commit('DEL_TAG', this.tag)
        },

        //新增按钮
        CreateData() {
            //是否显示分组
            if (this.displayGroup) {
                if (this.clickGroupID === 0) {
                    this.$Util.MsgBox('请先点击选择当前新增对应的分组', 0)
                    return
                }
            }
            this.$Util.NavigateTo(this.detailPath, { id: 0, groupId: this.clickGroupID })
        },

        //删除选择的行
        RemoveData() {
            let checkRowKey = this.$refs.obGrid.selected
            let checkRowData = this.$refs.obGrid.selRowsData

            console.log('checkRowKey', checkRowKey)
            console.log('checkRowData', checkRowData)
            // for (let i = 0; i < checkRowData.length; i++){
            //     let id = checkRowData[i].ID
            //     let name = checkRowData[i].Name

            if (this.$Util.IsNull(this.checkData)) {
                this.$Util.MsgBox('请先点击需要删除的数据行！', 0)
                return
            }
            let id = this.checkData.ID
            let name = this.checkData.Number + ' - ' + this.checkData.Name
            this.$confirm('是否删除：' + name + '?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$BizApi.RemoveModel(this.entity, id, () => {
                    this.RefreshData()

                    this.checkData = {}
                })
            })
            // }
        }
    }
}
</script>

<style></style>
