<template>
    <el-button-group>
        <el-button v-for="(item, index) in buttons" :key="index" :size="size" :type="type" plain :icon="item.icon" :disabled="item.disabled" @click="ButtonEvent(item.event)">{{
            item.label
            }}</el-button>
    </el-button-group>
</template>

<script>
  export default {
    name: "ObButtons",
    props: {
      size: {
        type: String,
        default: 'mini'
      },
      type: {
        type: String,
        default: 'primary'
      },
      buttons: {
        type: Array,
        default: () => {
          return [{label: '', event: '', icon: '', disabled: false }]
        }
      },
      parentVue: {
        type: Object
      }
    },
    methods: {
      ButtonEvent(eveName) {
        this.parentVue[eveName]()
      }
    }
  };
</script>

<style scoped>

</style>
