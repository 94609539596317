<template>
    <div class="bsy-float-button">
        <el-button-group>
            <el-button style="width: 200px" v-for="(item, index) in buttons" :key="index" :type="type" :icon="item.icon" :disabled="item.disabled"
                       @click="ButtonEvent(item.event)" :loading="loading">
                {{item.label}}
            </el-button>
        </el-button-group>
    </div>
</template>

<script>

export default {
    name: "bsy-buttons",
    props: {
        type: {
            type: String,
            default: 'primary'
        },
        buttons: {
            type: Array,
            default: () => {
                return [{label: '', event: '', icon: '', disabled: false }]
            }
        },
        parentVue: {
            type: Object
        }
    },
    computed: {
        loading(){
            // let val = this.$Util.GetStore("bsy-loading")
            // return this.$Util.IsNull(val) ? false : val
        }
    },
    data(){
      return{

      }
    },
    mounted() {

    },
    methods: {
        //调用父项方法
        ButtonEvent(eveName) {
            this.parentVue[eveName]()
        },

        //设置加载状态
        onSetLoading(val){
            this.loading = val
        }
    }
}
</script>

<style lang="scss" scoped>
.bsy-float-button{
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 40px;
    background: #eeeeee;
    box-shadow: 0 0 4px 0 #999999;
    border-radius: 2px;
    z-index: 10;
}
</style>
