<template>
    <div>
        <el-card class="bsy-bill-card" v-if="currentClientID !== 'Admin'">
            <div slot="header">
                <span>用户信息</span>
            </div>
            <el-form label-position="right" label-width="120px" :disabled="disabled">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="用户编码">
                            <el-input disabled v-model="userModel.Number" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="用户名称">
                            <el-input v-model="userModel.Name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="联系电话">
                            <el-input v-model="userModel.Phone" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="电子邮箱">
                            <el-input v-model="userModel.Email" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="管理员">
                            <el-select v-model="userModel.IsAdmin" disabled style="width: 100%">
                                <el-option label="是" :value="true"/>
                                <el-option label="否" :value="false"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <el-card class="bsy-bill-card">
            <div slot="header">
                <span>组织信息</span>
            </div>
            <el-form :model="billModel" :rules="rules" ref="ruleForm" label-position="right" label-width="120px" :disabled="disabled">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="社会组织编码">
                            <el-input readonly v-model="billModel.Number" placeholder="保存后自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="社会组织名称" prop="Name">
                            <el-input v-model="billModel.Name" placeholder="请输入社会组织名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="社会组织性质" prop="CommunityTypeID">
                            <el-select v-model="billModel.CommunityTypeID" placeholder="请选择社会组织性质" style="width: 100%">
                                <div v-for="(item,index) in CommunityTypeList" :key="index">
                                    <el-option :label="item.Name" :value="item.ID"/>
                                </div>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="公司电话" prop="Phone">
                            <el-input v-model="billModel.Phone" placeholder="请输入公司电话"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="营业执照" prop="License">
                            <el-input v-model="billModel.License" placeholder="请输入营业执照"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="省市区域">
                            <el-cascader style="width: 100%" :options="areaOptions" v-model="areaSelVal" @change="onAreaChange" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="具体地址" prop="Address">
                            <el-input v-model="billModel.Address" placeholder="请输入具体地址"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card class="bsy-bill-card">
            <div slot="header">
                <span>其他信息</span>
            </div>
            <el-form :model="billModel" label-position="right" label-width="100px" :disabled="disabled">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="创建时间">
                            <el-input readonly v-model="billModel.CreatedOn" placeholder="保存后自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="billModel.CreatedBy" placeholder="保存后自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="修改时间">
                            <el-input readonly v-model="billModel.ModifiedOn" placeholder="保存后自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="修改人">
                            <el-input readonly v-model="billModel.ModifiedBy" placeholder="保存后自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <bsy-buttons ref="BSYButtons" :buttons="buttons" :parentVue="thisVue"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BsyButtons from "../../components/bsy-ui/bsy-buttons";
import { CodeToText, regionData } from 'element-china-area-data'

export default {
    name: 'community-detail',
    components: {BsyButtons},
    computed: {
        ...mapGetters(['USER', 'collapsed']),
    },
    data() {
        return {
            thisVue: this,
            buttons: [],
            entity: 'Community',
            disabled: false,
            currentClientID: '',

            // 地区选择
            areaSelVal: [],
            areaOptions: regionData,

            userModel: {},
            billID:0,
            billModel: {},
            CommunityTypeList: [],

            rules: {
                Name: [
                    { required: true, message: '请输入企业名称', trigger: 'blur' },
                ],
                License: [
                    { required: true, message: '请输入营业执照编号', trigger: 'blur' },
                ],
                Phone: [
                    { required: true, message: '请输入公司电话', trigger: 'blur' },
                ],
                Address: [
                    { required: true, message: '请输入具体地址', trigger: 'blur' },
                ],
            },
        }
    },
    mounted() {
        this.onInit()

        this.currentClientID = this.$Util.GetStore("CurrentClientID")
        switch (this.currentClientID){
            //管理员
            case "Admin":
                this.billID = Number(this.$route.query.id)
                if (this.billID === 0) {
                    this.onCreate()
                }else{
                    this.onGetModel()
                    this.disabled = true
                }
                break

            // //企业机构
            // case "Enterprise":
            //     //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
            //     this.buttons = [{ label: '保存', event: 'onSave', icon: 'el-icon-check', disabled: false }]
            //     break

            //社会组织
            case "Community":
                this.buttons = [{ label: '保存', event: 'onSave', icon: 'el-icon-check', disabled: false }]
                this.userModel = this.$Util.GetStore("UserInfo")
                this.billID = this.userModel.CommunityID

                if (this.billID === 0) {
                    this.onCreate()
                }else{
                    this.onGetModel()
                }
                break
        }
    },
    methods: {
        onInit(){
            // 社会组织性质
            this.$API.GetList('CommunityType', {}, (resp) => {
                this.CommunityTypeList = resp.Data.Items
            })
        },
        onCreate(){
            this.$API.Create(this.entity, (resp) => {
                this.billModel = resp.Data

                this.onInitArea(this.billModel.RegionCode)
            })
        },
        onGetModel() {
            this.$API.GetModel(this.entity, this.billID, (resp) => {
                this.billModel = resp.Data

                this.onInitArea(this.billModel.RegionCode)
            })
        },

        //初始化地区组件需要的值
        onInitArea(regionCode){
            if(this.$Util.IsNull(regionCode.trim())){
                regionCode = "440605"
                this.billModel.RegionCode = "440605"
            }
            let province = regionCode.substr(0, 2)
            let city = regionCode.substr(2, 2)
            this.areaSelVal = [ province + "0000", province + city + "00", regionCode]

            this.onSetAreaToText()
        },

        // 地区组件选择值改变事件
        onAreaChange(arr) {
            this.areaSelVal = arr

            this.onSetAreaToText()
        },

        // 地区代码转换中文省市区
        onSetAreaToText(){
            this.billModel.Province = CodeToText[this.areaSelVal[0]]
            this.billModel.City = CodeToText[this.areaSelVal[1]]
            this.billModel.District = CodeToText[this.areaSelVal[2]]
            this.billModel.RegionCode = this.areaSelVal[2]
        },

        // 保存
        onSave() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    if(this.currentClientID === 'Admin'){
                        // 保存
                        this.$API.Save(this.entity, this.billModel, resp => {
                            this.$Util.MsgBox(resp.Message, 1)

                            this.billModel = resp.Data
                        })
                    }else{
                        // 保存组织档案
                        this.$API.Save(this.entity, this.billModel, resp => {
                            this.billID = resp.Data.ID
                            this.billModel = resp.Data
                            this.userModel.EnterpriseID = this.billID
                            this.userModel.ClientID = this.$Config.api.clientID + "Community"

                            // 保存用户
                            this.$API.PostService('CommunityUser/UpdateProfile', this.userModel, result => {
                                this.$Util.MsgBox(resp.Message, 1)

                                this.userModel = result.Data
                                this.$Util.SetStore("UserInfo", result.Data)
                            })
                        })
                    }
                } else {
                    return false;
                }
            });
        }
    }
}
</script>

<style scoped lang="scss">
.bsy-bill-card{
    margin-bottom: 5px;
}
</style>
