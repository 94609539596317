<template>
    <app-container>
        <el-row>
            <el-col :span="3" v-if="allowStatus" >
                <el-select v-model="statusVal" :placeholder="statusList[0].label" style="width: 100%">
                    <div v-for="(item,index) in statusList" :key="index" >
                        <el-option :label="item.label" :value="item.value"/>
                    </div>
                </el-select>
            </el-col>
            <el-col :span="3" v-if="allowPayStatus" >
                <el-select v-model="payStatusVal" :placeholder="payStatusList[0].label" style="width: 100%">
                    <div v-for="(item,index) in payStatusList" :key="index" >
                        <el-option :label="item.label" :value="item.value"/>
                    </div>
                </el-select>
            </el-col>
            <el-col :span="3" v-if="DatePickSelect">
                <el-select v-model="datePickStr" :placeholder="DatePickList[0].label" style="width: 100%">
                    <div v-for="(item,index) in DatePickList" :key="index" >
                        <el-option :label="item.label" :value="item.value"/>
                    </div>
                </el-select>
            </el-col>
            <el-col :span="5" v-if="DatePickSelect">
                <el-date-picker
                    style="width: 100%"
                    v-model="dateRange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="下单开始日期"
                    end-placeholder="下单结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-col>
            <el-col :span="4">
                <el-input id="input" type="text" v-model="filterStr" :placeholder="QueryPlaceholder" @change="onRefresh" clearable />
            </el-col>
            <el-col :span="6">
                <el-button-group>
                    <el-button type="primary" @click="onRefresh" icon="el-icon-search">查询</el-button>
                    <el-button v-if="AddBtn" type="primary" @click="onCreate" icon="el-icon-circle-plus-outline">新增</el-button>
                    <el-button v-if="IsAdmin" type="primary" @click="dialogImportVisible=true" icon="el-icon-upload2">导入</el-button>
                </el-button-group>
            </el-col>
        </el-row>
        <el-table :data="listData.Items" ref="table" @row-dblclick="onDoubleSelected" border stripe v-loading="loading" :height="tableHeight" style="width: 100%;" >
<!--            <el-table-column fixed type="index" width="50"/>-->
            <!--显示的字段-->
            <div v-for="(item, index) in FieldList" :key="index">
                <el-table-column v-if="item.type == 'bool'" :prop="item.prop" :label="item.label" :width="item.width" :formatter="formatBoolean" show-overflow-tooltip/>
                <el-table-column v-else-if="item.type == 'image'" :prop="item.prop" :label="item.label" :width="item.width" show-overflow-tooltip>
                    <template :width="item.width" slot-scope="scope">
                        <img style="width:60px;height:60px;border:none;" :src="scope.row[item.prop]">
                    </template>
                </el-table-column>
                <el-table-column :fixed="index == 0 ? true : item.fixed" v-else :prop="item.prop" :label="item.label" :width="item.width" show-overflow-tooltip/>
            </div>
            <el-table-column v-if="Operation.length > 0" fixed="right" label="操作" :width="100">
                <template slot-scope="scope">
                    <div v-for="(item,index) in Operation" :key="index">
						<el-button v-if="item == 'view'" type="text" size="small" @click="onEdit(scope.row)">详情</el-button>
                        <el-button v-if="item == 'delete'" type="text" size="small" @click="onDelete(scope.row)">删除</el-button>
						<el-button v-if="item == 'disable'" type="text" size="small" @click="onDisable(scope.row)">禁用</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="currentSize"
            :total="listData.Total"
            :page-sizes="[20, 50, 100, 200, 500]"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>

        <el-dialog title="导入" :visible.sync="dialogImportVisible">
            <el-upload
                drag
                action=""
                multiple
                :limit="1"
                :auto-upload="false"
                :file-list="fileList"
                :before-remove="beforeRemove"
                :on-exceed="handleExceed"
                :on-change="handleAvatarSuccessUpload"
                accept=".xls,.xlsx">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传xlsx/png文件</div>
            </el-upload>
            <el-button type="primary" @click="onImport">确定</el-button>
            <el-button type="primary" @click="dialogImportVisible=false">取消</el-button>
        </el-dialog>
    </app-container>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from "moment";

export default {
    name: 'temp-bill-list',
    computed: {
        ...mapGetters(['tag', 'UserInfo']),
        tableHeight() {
            return this.winHeight - 170 + 'px'
        }
    },
    watch: {
        listData() {
            this.doLayout()
        }
    },
    props:{
        //是否启用状态查询
        allowStatus: {
            type: Boolean,
            default: true
        },
        //状态列表
        statusList:{
            type: Array,
            default: () => [
                { label: "全部状态", value: "1==1" },
                { label: "待提交", value: 'Status=="A"' },
                { label: "已提交", value: 'Status=="B"' },
                { label: "已受理", value: 'Status=="C"' },
                { label: "已驳回", value: 'Status=="D"' },
                { label: "已告知", value: 'Status=="E"' },
                { label: "已结案", value: 'Status=="G"' },
                { label: "已关闭", value: 'Status=="F"' },
            ]
        },
        //是否启用付款状态查询
        allowPayStatus: {
            type: Boolean,
            default: false
        },
        //状态列表
        payStatusList:{
            type: Array,
            default: () => [
                { label: "全部付款状态", value: "1==1" },
                { label: "未付款", value: 'Status=="A"' },
                { label: "部分付款", value: 'Status=="B"' },
                { label: "全额付款", value: 'Status=="C"' },
            ]
        },
        //是否显示新增按钮
        AddBtn: {
            type: Boolean,
            default: true
        },
        //是否显示导入按钮
        ImportBtnDisable: {
            type: Boolean,
            default: false
        },
        //搜索框提示词
        QueryPlaceholder: {
            type: String,
            default: '关键词模糊搜索'
        },
        //是否启用状态查询
        DatePickSelect: {
            type: Boolean,
            default: true
        },
        //时间过滤查询列表
        DatePickList:{
            type: Array,
            default: () => [
                { label: '业务日期', value: 'BizDate' },
                { label: '提交日期', value: 'SubmittedOn' },
                { label: '调解开始', value: 'MediateStartDate' },
            ]
        },
        //接口实体类名
        Entity:{
            type: String,
            default: ''
        },
        //导入接口参数
        ImportMethod:{
            type: String,
            default: ''
        },
        //接口实体方法
        Method:{
            type: String,
            default: 'GetList'
        },
        //详情的路径
        DetailPath:{
            type: String,
            default: ''
        },
        //字段
        FieldList:{
            type: Array,
            default: () => [
                { label: '编码', prop: 'Number', width: '100', fixed: true },
                { label: '名称', prop: 'Name' }
            ]
        },
        //操作
        Operation:{
            type: Array,
            default: () => []
        },
        //是否允许双击
        DoubleClick:{
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            winHeight: 0,
            IsAdmin: false,
            dialogImportVisible: false,
            fileList: [],

            loading: true,
            filterStr: '', //快捷查询的文本框
            statusVal: '1==1', //单据状态快捷查询下拉选择值
            payStatusVal: '1==1', //付款状态快捷查询下拉选择值
            datePickStr: 'BizDate',//日期快捷查询的下拉选择框
            currentPage: 1,
            currentSize: 20,
            listData: [{Total: 0,Items: []}],

            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const today = new Date();
                        picker.$emit('pick', [today, today]);
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', [date, date]);
                    }
                },{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                },
                    {
                        text: '最近六个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
            },
            dateRange: []
        }
    },
    mounted() {
        //获取窗体的高度
        this.winHeight = window.innerHeight
        let that = this
        window.onresize = () => {
            return (() => {
                that.winHeight = window.innerHeight
            })()
        }

        //管理员身份才有导入按钮
        if(this.$Util.GetStore("CurrentClientID") === "Admin" && this.ImportBtnDisable){
            this.ImportBtn = true
        }

        //最近六个月
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
        this.dateRange = [start, end]

        this.onRefresh()
    },
	activated() {
		this.doLayout()
	},
    methods: {
		doLayout() {
			this.$nextTick(() => {
				this.$refs.table.doLayout()
			})
		},
        formatBoolean: function (row, column, cellValue) {
            let ret = ''
            if (cellValue) {
                ret = "是"
            } else {
                ret = "否"
            }
            return ret;
        },
        handleSizeChange(val) {
            this.currentSize = val
            this.onRefresh()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.onRefresh()
        },
        // 查询按钮 - 获取接口数据
        onRefresh() {
            let start = moment(this.dateRange[0]).format('YYYY-MM-DD')
            let end = moment(this.dateRange[1]).add(1, 'days').format('YYYY-MM-DD')

            let filter = ""

            //是否启用日期过滤
            if(this.DatePickSelect){
                filter = this.datePickStr + " >= \"" + start + "\" && " + this.datePickStr + " <\"" + end + "\""
            }

            //是否启用单据状态过滤
            if(this.allowStatus){
                filter += ' && ' + this.statusVal
            }

            //是否启用付款状态过滤
            if(this.allowPayStatus){
                filter += ' && ' + this.payStatusVal
            }

            let params = {
                Page: this.currentPage,
                Size: this.currentSize,
                Filter: filter,
                Keyword:  this.filterStr,
                Sort: "CreatedOn desc"
            }
            this.loading = true
            if(this.Method === "Service"){
                this.$API.PostService(this.Entity + '/GetList', params, resp => {
                    if(resp.Status === 1){
                        this.listData = resp.Data
                        this.loading = false
                    }
                })
            }
            else {
                this.$API.GetList(this.Entity, params, resp => {
                    if(resp.Status === 1){
                        this.listData = resp.Data
                        this.loading = false
                    }
                })
            }
        },

        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件！`);
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${ file.name }？`);
        },
        // 上传选择图片
        handleAvatarSuccessUpload(res, file){
            this.fileList = file
        },
        onImport(){
            if(this.fileList.length === 0){
                this.$Util.MsgBox("请先选择需要导入的文件！", 0)
                return
            }

            //定义参数
            let formData = new FormData()
            formData.append('ClientID', this.$Config.api.clientID + this.$Util.GetStore("CurrentClientID"))
            formData.append('File', this.fileList[0].raw)

            this.$API.PostService(this.ImportMethod, formData, resp => {
                if(resp.Status === 1){
                    this.dialogImportVisible = false
                    this.$Util.MsgBox(resp.Message, 1)
                    this.onRefresh()
                }
            })
        },

        //新增按钮
        onCreate() {
            this.$Util.NavigateTo(this.DetailPath, { id: 0 })
        },

        //双击行
        onDoubleSelected(data){
            if(this.DoubleClick)
                this.$Util.NavigateTo(this.DetailPath, { id: data.ID })
        },

        //编辑
        onEdit(data){
            this.$Util.NavigateTo(this.DetailPath, { id: data.ID })
        },

        //删除
        onDelete(data){
            this.$confirm(this.$Util.IsNull(data.Number) ? '是否删除' : '是否删除' + data.Number, '提示' , {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$API.Remove(this.Entity, data.ID, () => {
                    this.onRefresh()
                })
            })
        },

		//禁用
		onDisable(data){
		    this.$confirm(this.$Util.IsNull(data.Number) ? '是否禁用' : '是否禁用' + data.Name, '提示' , {
		        confirmButtonText: '确定',
		        cancelButtonText: '取消',
		        type: 'warning'
		    }).then(() => {
		        this.$API.Disable(this.Entity, data.ID, () => {
		            this.onRefresh()
		        })
		    })
		}
    }
}
</script>

<style>
.el-range-editor--small.el-input__inner {
    /*height: 28px;*/
}
</style>
