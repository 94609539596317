<template>
    <div>
        <app-container>
            <el-form :model="billModel" :rules="rules" ref="ruleForm" label-position="right" label-width="100px" :disabled="disabled">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="编号">
                            <el-input readonly v-model="billModel.Number" placeholder="保存后自动生成" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="名称" prop="Name">
                            <el-input v-model="billModel.Name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="类型" prop="GroupID">
                            <el-select v-model="billModel.GroupID" placeholder="分类" style="width: 100%">
                                <div v-for="(item,index) in groupList" :key="index">
                                    <el-option :label="item.Name" :value="item.ID"/>
                                </div>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="顺序" prop="Seq">
                            <el-input v-model="billModel.Seq" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="链接地址" prop="LinkUrl">
                            <el-input v-model="billModel.LinkUrl" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="创建时间">
                            <el-input readonly v-model="billModel.CreatedOn" placeholder="保存后自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="billModel.CreatedBy" placeholder="保存后自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="修改时间">
                            <el-input readonly v-model="billModel.ModifiedOn" placeholder="保存后自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="修改人">
                            <el-input readonly v-model="billModel.ModifiedBy" placeholder="保存后自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="图片">
                    <b-s-y-upload ref="BSYUpload" pictureTips="*请保持图片尺寸比例（如：590 x 300）否则将影响显示效果"
                                  :DataValue="billModel.ImageUrl" @onSuccessChange="onSuccessChange"/>
                </el-form-item>
            </el-form>

            <el-dialog :visible.sync="imageDialog">
                <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
        </app-container>

        <bsy-buttons ref="BSYButtons" :buttons="buttons" :parentVue="thisVue"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BsyButtons from "@/components/bsy-ui/bsy-buttons";
import BSYUpload from "@/components/bsy-ui/bsy-upload"

export default {
    //轮播图
    name: 'banner-detail',
    components: { BsyButtons, BSYUpload },
    computed: {
        ...mapGetters(['USER', 'collapsed']),
    },
    data() {
        return {
            // 实体类名
            entity: 'Banner',

            disabled: false,
            thisVue: this,
            buttons: [{ label: '保存', event: 'onSave', icon: 'el-icon-check', disabled: false }],

            groupList: [],

            imageList: [],
            imageDialog: false,
            dialogImageUrl: '',

            billID: 0, //单据表头ID
            billModel: {
                Name: '',
                Number: '',
                Seq: '',
                Group: {ID: '', Name: ''},
                GroupID: 1,
                ImageUrl: []
            },
            rules: {
                Name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
            }
        }
    },
    mounted() {
        this.onGetGroupList()

        this.billID = Number(this.$route.query.id)
        if (this.billID === 0) {
            this.onCreate()
        }else{
            this.onGetModel()
        }
    },
    methods: {
        // 分类
        onGetGroupList(){
            this.$API.GetList(this.entity + 'Group', {}, (resp) => {
                this.groupList = resp.Data.Items
            })
        },
        onCreate() {
            this.$API.Create(this.entity, (resp) => {
                this.billModel = resp.Data
            })
        },
        onGetModel() {
            this.$API.GetModel(this.entity, this.billID, (resp) => {
                // 整理显示的图片格式
                if(!this.$Util.IsNull(resp.Data.ImageUrl)){
                    resp.Data.ImageUrl = [{url: resp.Data.ImageUrl}]
                }

                this.billModel = resp.Data
                if(this.billModel.Status === "C"){
                    this.disabled = true
                    this.buttons = [
                        { label: '撤销', event: 'onUnAudit', icon: 'el-icon-check', disabled: false },
                    ]
                }else{
                    this.disabled = false
                    this.buttons = [
                        { label: '保存', event: 'onSave', icon: 'el-icon-check', disabled: false },
                        { label: '发布', event: 'onAudit', icon: 'el-icon-check', disabled: false },
                    ]
                }
            })
        },

        // 上传轮播图
        onSuccessChange(file){
            this.imageList = file
        },

        async onUploadImg(){
            let formData = new FormData()
            formData.append('BannerID', this.billID)
            formData.append('GroupID', this.billModel.GroupID)

            //遍历图片
            for (const pic of this.imageList){
                formData.append('File', pic.raw)
            }

            await this.$API.SyncPostService("File/Upload" + this.entity + "/PostForm", formData)

            this.onGetModel()
        },

        // 保存
        onSave() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    if(this.billModel.GroupID === 0){
                        this.$Util.MsgBox('请选择类型', -1)
                        return
                    }

                    if(this.imageList.length > 0)
                        this.billModel.ImageUrl = ""
                    else
                        this.billModel.ImageUrl = this.$Util.IsNull(this.billModel.ImageUrl) ? "" : this.billModel.ImageUrl[0].url

                    this.$API.Save(this.entity, this.billModel, resp => {
                        this.$Util.MsgBox(resp.Message, 1)
                        this.billID = resp.Data.ID

                        if(this.imageList.length > 0)
                            this.onUploadImg()
                        else
                            this.onGetModel()
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        onAudit(){
            if(this.billID === 0) return

            this.$API.Audit(this.entity, this.billID, () =>{
                this.onGetModel()
            })
        },

        //撤销
        onUnAudit(){
            if(this.billID === 0) return

            this.$API.UnAudit(this.entity, this.billID, () =>{
                this.onGetModel()
            })
        },
    }
}
</script>

<style lang="scss">
.el-tabs__header {
    margin-bottom: 5px;
}
</style>

<style scoped lang="scss">

</style>
