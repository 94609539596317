<template>
    <div class="page-index">
        <div style="height: 20px"/>

        <el-row :gutter="20">
            <el-col :span="12" style="height: 300px;">
                <el-carousel trigger="click" height="300px">
                    <el-carousel-item v-for="(item,index) in bannerList" :key="index">
                        <el-image :src="item.ImageUrl"/>
                    </el-carousel-item>
                </el-carousel>
            </el-col>
            <el-col :span="12">
                <div class="bsy-card" style="height: 260px;">
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="成功案例" name="001">
                            <el-row v-for="(item,index) in articleList2" :key="index" style="height: 30px;line-height: 30px">
                                <el-col :span="19">
                                    <el-link type="primary" icon="el-icon-link" @click="onClickNews(item)">{{item.Name}}</el-link>
                                </el-col>
                                <el-col :span="5" style="color: #999999;">
                                    <div style="font-size: large;margin-top: 5px;text-align: right">{{ item.BizDateText }}</div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-col>
        </el-row>

        <div style="height: 20px"/>

        <el-row :gutter="20" type="flex" justify="center">
            <el-col :span="6" v-for="(item,index) in buttonList" :key="index">
                <div class="bsy-card">
                    <div style="cursor: pointer;text-align: center" @click="onClick(item)">
                        {{item.caption}}<i class="el-icon-arrow-right"/>
                    </div>
                </div>
            </el-col>
        </el-row>

        <el-image :src="require('@/assets/images/index/index_info.jpg')" style="width: 100%;margin-top:20px;margin-bottom: 20px"/>

        <el-row :gutter="20" type="flex" justify="center">
            <el-col :span="6" v-for="(item,index) in imageList" :key="index">
                <div style="box-shadow: 0 0 5px #c1c1c1;cursor: pointer;" @click="onClick(item)">
                    <el-image style="width: 285px;height: 285px" :src="item.img"/>
                    <div style="text-align: center;padding: 10px">{{item.caption}}</div>
                </div>
            </el-col>
        </el-row>

        <div style="height: 20px"/>

        <el-row :gutter="20">
            <el-col :span="12" style="height: 300px;">
                <div class="bsy-card" style="height: 260px;">
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="信用动态" name="001">
                            <el-row v-for="(item,index) in articleList1" :key="index" style="height: 30px;line-height: 30px">
                                <el-col :span="19">
                                    <el-link type="primary" icon="el-icon-link" @click="onClickNews(item)">{{item.Name}}</el-link>
                                </el-col>
                                <el-col :span="5" style="color: #999999;">
                                    <div style="font-size: large;margin-top: 5px;text-align: right">{{ item.BizDateText }}</div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="bsy-card" style="height: 260px;">
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="政策法规" name="001">
                            <el-row v-for="(item,index) in articleList3" :key="index" style="height: 30px;line-height: 30px">
                                <el-col :span="19">
                                    <el-link type="primary" icon="el-icon-link" @click="onClickNews(item)">{{item.Name}}</el-link>
                                </el-col>
                                <el-col :span="5" style="color: #999999;">
                                    <div style="font-size: large;margin-top: 5px;text-align: right">{{ item.BizDateText }}</div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'index',
    components: {},
    computed: {

    },
    data() {
        return {
            activeName: '001',
            bannerList: [],
            articleList1: [],
            articleList2: [],
            articleList3: [],

            buttonList: [
                { type: 0, caption: '信用查询', url: 'https://www.creditchina.gov.cn/xinyongfuwu/tongyishehuixinyongdaimachaxunzhuanlan/' },
                { type: 1, caption: '政策法规', path: 'policy-list' },
                { type: 1, caption: '服务范围', path: 'scope-list' },
                { type: 1, caption: '下载中心', path: 'download' },
            ],
            imageList: [
                { type: 1, caption: '提交申请', img: this.$Config.api.fileUrl + 'image/index_tjsq.png', path: 'login' },
                { type: 1, caption: '政策法规', img: this.$Config.api.fileUrl + 'image/index_zcfg.png', path: 'policy-list' },
                { type: 1, caption: '服务范围', img: this.$Config.api.fileUrl + 'image/index_fwfw.png', path: 'scope-list' },
                { type: 0, caption: '信用师学习平台', img: this.$Config.api.fileUrl + 'image/index_xysxx.png', url: 'https://gdcmma-101.365hjy.com/' },
            ]
        }
    },
    mounted() {
        //轮播图
        this.$API.GetResourceList('Banner', {Sort: 'Seq, CreatedOn desc'}, resp =>{
            this.bannerList = resp.Data.Items
        })

        //文章-信用动态
        this.$API.GetResourceList('Article', { Filter: "GroupID==3 and Status==\"C\"", Size: 6, Sort: 'Seq,BizDate desc' }, resp =>{
            this.articleList1 = resp.Data.Items
        })

        //文章-成功案例
        this.$API.GetResourceList('Article', { Filter: "GroupID==2 and Status==\"C\"", Size: 6, Sort: 'Seq,BizDate desc' }, resp =>{
            this.articleList2 = resp.Data.Items
        })

        //文章-政策法规
        this.$API.GetResourceList('Article', { Filter: "GroupID==1 and Status==\"C\"", Size: 6, Sort: 'Seq,BizDate desc' }, resp =>{
            this.articleList3 = resp.Data.Items
        })
    },
    methods: {
        onResquest(){
            this.$router.push( { name: 'register'} )
        },
        onOpenWindow(url, title){
            // this.$Util.OpenWindow(url, title)
            window.open(url)
        },
        onClick(data){
            if(data.type === 0){
                window.open(data.url)
            }else{
                this.$router.push( { name: data.path } )
            }
        },
        onClickNews(data){
            // this.$router.push( { name: data.path, query: {} } )
            this.$router.push({ name: "news", params: { data: data } })
        }
    }
}
</script>

<style lang="scss">
::-webkit-scrollbar {
    width: 0 !important;
}
::-webkit-scrollbar {
    width: 0 !important;height: 0;
}

.page-index{
    //直接在index父项定义
    //margin: 0 auto;
    //width:1200px;

    .bsy-card{
        box-shadow: 0 0 5px #c1c1c1;
        padding: 20px;
    }

    .el-tabs__item{
        font-size: larger;
        font-weight: bold;
        margin-top: -10px;
    }
    .el-tabs__item.is-active {
        color: #0948A5;
    }
    .el-tabs__active-bar{
        background-color: #0948A5;
    }
    .el-link--inner{
        font-size: 16px;
    }
    .bsy-border-radius{
        box-shadow: 0 0 10px #c1c1c1;
    }
}
</style>
