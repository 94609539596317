<template>
    <div>
        <app-container style="padding-bottom: 0px">
            <!-- 操作按钮 -->
            <el-button-group>
                <el-button v-for="(item, index) in buttonsArray" :key="index" size="mini" type="primary" plain :icon="item.icon" :disabled="item.disabled" @click="onButtonEvent(item.type)">
                    {{ item.label }}
                </el-button>
            </el-button-group>

            <!-- 基础资料模板 -->
            <ob-base-modal ref="obBase" @ReturnSelected="ReturnSelected" />

            <!-- 启用单据明细 -->
            <el-tabs v-if="useDetails">
                <el-tab-pane v-for="(tabs, tabIndex) in newDynamicForm" :key="tabIndex" :label="tabs.tabLabel">
                    <ob-form :disabled="!allowEdit" style="height: 95px">
                        <el-row>
                            <ob-form-item
                                v-for="(item, index) in tabs.forms"
                                v-model="ModelBill[item.value]"
                                :modelField="item.value"
                                :type="item.type"
                                :key="index"
                                :col="item.col"
                                :required="item.required"
                                :label="item.label"
                                :labelWidth="item.labelWidth"
                                :placeholder="item.placeholder"
                                :readonly="item.readonly"
                                :rules="item.rules"
                                :field="item.field"
                                :list="item.list"
                                :searchObj="item.searchObj"
                                @click="ShowBaseModal(item.label, item.value, item.searchObj.entity, 0)"
                                @handleSelect="SetBaseData"
                            />
                        </el-row>
                    </ob-form>
                </el-tab-pane>
            </el-tabs>
            <app-container v-if="useDetails">
                <ob-buttons :buttons="entryButtons" :parentVue="thisVue" />
                <ob-grid
                    :page-size="15"
                    ref="obGrid"
                    :gridHeight="gridHeight"
                    :columns="tableColumns"
                    :multi-select="true"
                    :filter-row="false"
                    :paging="true"
                    :allowEdit="allowEdit"
                    @refreshGrid="refreshTable"
                />
            </app-container>

            <!-- 不启用单据明细 -->
            <div v-if="!useDetails" :style="'height: ' + (winHeight - 130) + 'px'">
                <div v-for="(tabs, tabIndex) in newDynamicForm" :key="tabIndex" :label="tabs.tabLabel">
                    <el-tabs>
                        <el-tab-pane :label="tabs.tabLabel">
							<!-- //这里 -->
                            <ob-form :disabled="!allowEdit">
                                <el-row>
                                    <ob-form-item
                                        v-for="(item, index) in tabs.forms"
                                        v-model="ModelBill[item.value]"
                                        :modelField="item.value"
                                        :type="item.type"
                                        :key="index"
                                        :col="item.col"
                                        :required="item.required"
                                        :label="item.label"
                                        :labelWidth="item.labelWidth"
                                        :placeholder="item.placeholder"
                                        :readonly="item.readonly"
                                        :rules="item.rules"
                                        :field="item.field"
                                        :list="item.list"
                                        :searchObj="item.searchObj"
                                        @click="ShowBaseModal(item.label, item.value, item.searchObj.entity, 0)"
                                        @handleSelect="SetBaseData"
                                    />
                                </el-row>
                            </ob-form>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </app-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment/moment'

var entryid = 1 //用来标识新增明细行的ID

export default {
    name: 'templateDetail',
    props: {
        // 标题
        title: {
            type: String,
            default: ''
        },
        // 实体类名
        entity: {
            type: String,
            default: ''
        },
        child: {
            type: String,
            default: 'Entry'
        },
        // Post方法
        method: {
            type: String,
            default: ''
        },
        // 是否启用单体明细
        useDetails: {
            type: Boolean,
            default: false
        },
        // 单据 - 操作按钮集合
        buttonsGroup: {
            type: Array,
            default: () => [
                { label: '保存', type: '保存', icon: 'el-icon-circle-check', disabled: false },
                { label: '提交', type: '提交', icon: 'el-icon-open', disabled: true },
                { label: '撤销', type: '撤销', icon: 'el-icon-turn-off', disabled: true },
                { label: '审核', type: '审核', icon: 'el-icon-open', disabled: true },
                { label: '反审', type: '反审', icon: 'el-icon-turn-off', disabled: true }
                // { label: '启用', type: '启用', icon: 'el-icon-open', disabled: true },
                // { label: '禁用', type: '禁用', icon: 'el-icon-turn-off', disabled: true },
            ]
        },

        // 添加单据操作按钮集合
        addButtons: {
            type: Array,
            default: null
        },

        // 单据明细 - 操作按钮集合
        entryButtons: {
            type: Array,
            default: () => [
                { label: '新增行', event: 'onAddRow', icon: '', disabled: false },
                { label: '删除行', event: 'onRemoveRow', icon: '', disabled: false }
            ]
        },
        // 单据头 - 动态自定义表单
        dynamicForm: {
            type: Array,
            default: null
        },
        // 单据明细 - 表格数据自定义列
        tableColumns: {
            type: Array,
            default: null
        }
    },
    computed: {
        ...mapGetters(['USER', 'collapsed']),

        gridHeight: function() {
            let rstHeight = this.winHeight - 305
            return rstHeight
        }
    },
    data() {
        return {
            thisVue: this, //ob-buttons parentVue
            winHeight: 0, //窗体的高度
            buttonsArray: this.buttonsGroup,
            newDynamicForm: this.dynamicForm,

            billID: 0, //单据表头ID
            allowEdit: true, //是否可编辑(默认true：允许编辑)
            ModelBill: {}, //表单对象
            ModelEntry: [] //表体数组
        }
    },
    mounted() {
        //获取窗体的高度
        this.winHeight = window.innerHeight
        let that = this
        window.onresize = () => {
            return (() => {
                that.winHeight = window.innerHeight
            })()
        }

        // 初始化动态表单
        this.initDynamicForm()

        //添加单据操作按钮集合
        if (!this.$Util.IsNull(this.addButtons)) {
            this.buttonsArray = this.buttonsArray.concat(this.addButtons)
        }

        this.billID = Number(this.$route.query.id)

        if (this.billID === 0) {
            //新增 - 初始化
            this.initData()
        } else {
            //详情 - 获取数据
            this.refreshData()
        }
    },
    methods: {
        // 初始化动态表单
        initDynamicForm() {
            let recordObj = {
                tabLabel: '操作记录',
                forms: [
                    {
                        label: '创建人',
                        value: 'CreatedBy',
                        type: 'text',
                        placeholder: '保存后自动生成',
                        readonly: true,
                        rules: []
                    },
                    {
                        label: '创建日期',
                        value: 'CreatedOn',
                        type: 'datetime',
                        placeholder: '保存后自动生成',
                        readonly: true,
                        rules: { required: true, message: '请输入邮箱地址', trigger: 'blur' }
                    },
                    {
                        label: '修改人',
                        value: 'ModifiedBy',
                        type: 'text',
                        placeholder: '修改后自动生成',
                        readonly: true,
                        rules: []
                    },
                    {
                        label: '修改日期',
                        value: 'ModifiedOn',
                        type: 'datetime',
                        placeholder: '修改后自动生成',
                        readonly: true,
                        rules: []
                    },
                    {
                        label: '审核人',
                        value: 'CheckedBy',
                        type: 'text',
                        placeholder: '审核后自动生成',
                        readonly: true,
                        rules: []
                    },
                    {
                        label: '审核日期',
                        value: 'CheckedOn',
                        type: 'datetime',
                        placeholder: '审核后自动生成',
                        readonly: true,
                        rules: []
                    },
                    {
                        label: '禁用人',
                        value: 'DisabledBy',
                        type: 'text',
                        placeholder: '禁用后自动生成',
                        readonly: true,
                        rules: []
                    },
                    {
                        label: '禁用日期',
                        value: 'DisabledOn',
                        type: 'datetime',
                        placeholder: '禁用后自动生成',
                        readonly: true,
                        rules: []
                    }
                    // {
                    //     label: '业务单元',
                    //     value: 'BizUnit',
                    //     type: 'text',
                    //     placeholder: '自动生成',
                    //     readonly: true,
                    //     rules: []
                    // },
                    // {
                    //     label: '负责人',
                    //     value: 'Owner',
                    //     type: 'text',
                    //     placeholder: '自动生成',
                    //     readonly: true,
                    //     rules: []
                    // }
                ]
            }

            let newArr = this.dynamicForm
            newArr.push(recordObj)
            this.newDynamicForm = newArr
        },

        // 单据头 - 初始化数据源
        initData() {
            this.$BizApi.NewModel(this.entity, (resp) => {
                this.ModelBill = resp.Data

                this.ModelBill.GroupID = Number(this.$route.query.groupId)
                this.ModelBill.Status = 0 //默认创建状态
                this.ModelBill.StatusName = '创建' //默认创建状态
                this.ModelBill.CreatedOn = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                this.ModelBill.CreatedBy = this.USER.AccountID
            })

            //是否需要单据明细
            if (this.useDetails) {
                // 单据明细 - 绑定实体
                this.$refs.obGrid.SetLocalData(this.ModelEntry)
            }
        },

        // 刷新单据头 - 获取单据表头数据源
        refreshData() {
            this.$BizApi.GetModel(this.entity, this.billID, (resp) => {
                this.ModelBill = resp.Data
                this.ModelEntry = resp.Data.Entry

                //根据单据状态更改操作按钮权限
                this.ButtonStatusChange()

                //是否需要单据明细
                if (this.useDetails) {
                    // 单据明细 - 绑定实体
                    this.$refs.obGrid.SetLocalData(this.ModelEntry)
                }
            })
        },

        // 刷新明细表格 - 获取单据明细数据源
        refreshTable() {
            //判断当前页面是否新增单据
            if (this.billID === 0) {
                // 本地数据
                this.$refs.obGrid.SetLocalData(this.ModelEntry)
            } else {
                // 接口数据
                let filter = 'ParentID="' + this.billID + '"'
                // entity, filter, sort, lib, method, key, url
                this.$refs.obGrid.SetData(this.entity + this.child, filter, '', '', this.method)
            }
        },

        //明细表格 - 点击单元格打开对应的基础资料模板
        EntryClickBasic(title, modelField, entity, h, params) {
            //当前行的ID值
            let indexStr = params.data.ID

            //title, modelField, entity, method, indexStr
            this.ShowBaseModal(title, modelField, entity, indexStr)
        },

        //打开对应的基础资料模板选择基础资料
        ShowBaseModal(title, modelField, entity, indexStr) {
            this.$refs.obBase.ShowBaseModal(title, modelField, entity, 'GetListEx', indexStr)
        },

        //单据头 - 基础资料模板 - 选择后返回的数据集
        ReturnSelected(modelField, indexStr, checkData) {
            if (indexStr === 0) {
                this.ModelBill[modelField] = {}
                this.ModelBill[modelField] = checkData
            } else {
                //单据体
                for (let i = 0; i < this.ModelEntry.length; i++) {
                    if (this.ModelEntry[i].ID === indexStr) {
                        this.ModelEntry[i][modelField] = {}
                        this.ModelEntry[i][modelField] = checkData
                    }
                }
            }
            this.$nextTick(() => {
                this.$forceUpdate() //强制刷新
            })
        },

        //单据头 - 基础资料下拉列表返回的数据
        SetBaseData(modelField, data) {
            this.ModelBill[modelField] = {}
            this.ModelBill[modelField] = data

            // this.$nextTick(() => {
            //     this.$forceUpdate() //强制刷新
            // })
        },

        //根据单据状态更改操作按钮权限
        ButtonStatusChange() {
            /// 状态. 0-保存 1-提交 2-审核 3-重新审核 4-禁用/作废
            let thisStatus = this.ModelBill.Status
            this.allowEdit = thisStatus === 0 || thisStatus === 3 ? true : false

            //单据头 - 操作按钮是否允许编辑
            for (let i = 0; i < this.buttonsGroup.length; i++) {
                switch (thisStatus) {
                    case 0:
                    case 3:
                        if (this.buttonsGroup[i].label === '保存' || this.buttonsGroup[i].label === '提交' || this.buttonsGroup[i].label === '禁用') {
                            this.buttonsGroup[i].disabled = false
                        } else {
                            this.buttonsGroup[i].disabled = true
                        }
                        break

                    case 1:
                        if (this.buttonsGroup[i].label === '撤销' || this.buttonsGroup[i].label === '审核' || this.buttonsGroup[i].label === '禁用') {
                            this.buttonsGroup[i].disabled = false
                        } else {
                            this.buttonsGroup[i].disabled = true
                        }
                        break

                    case 2:
                        if (this.buttonsGroup[i].label === '反审' || this.buttonsGroup[i].label === '禁用') {
                            this.buttonsGroup[i].disabled = false
                        } else {
                            this.buttonsGroup[i].disabled = true
                        }
                        break

                    case 4:
                        if (this.buttonsGroup[i].label === '启用') {
                            this.buttonsGroup[i].disabled = false
                        } else {
                            this.buttonsGroup[i].disabled = true
                        }
                        break
                }
            }

            //单据体明细行按钮是否允许编辑
            for (let i = 0; i < this.entryButtons.length; i++) {
                this.entryButtons[i].disabled = !this.allowEdit
            }
        },

        // 新增单据明细行
        onAddRow() {
            this.$BizApi.NewModel(this.entity + this.child, (resp) => {
                let newData = resp.Data
                newData.ID = 'new' + entryid

                this.ModelEntry.push(newData)
                entryid += 1
            })
        },

        // 删除单据明细行
        onRemoveRow() {
            this.$refs.obGrid.onDelRow()
        },

        //单据头按钮对应操作
        onButtonEvent(type) {
            switch (type) {
                case '撤销':
                case '反审':
                case '禁用':
                    this.$confirm('是否' + type + '当前单据', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.onSave(type)
                    })
                    break

                default:
                    this.onSave(type)
                    break
            }
        },

        // 保存
        onSave(type) {
            //是否需要单据明细
            if (this.useDetails) {
                if (this.ModelEntry.length === 0) {
                    this.$Util.MsgBox('请先新增行！', 0)
                    return
                }

                //将新增的行ID标识改为0
                for (let i = 0; i < this.ModelEntry.length; i++) {
                    let thisIDStr = JSON.stringify(this.ModelEntry[i].ID)
                    if (thisIDStr.indexOf('new') === 1) {
                        this.ModelEntry[i].ID = 0
                    }
                }
                this.ModelBill.Entry = this.ModelEntry
            }
            console.log('onSaveJson', JSON.stringify(this.ModelBill))
            /// 状态. 0-保存 1-提交 2-审核 3-重新审核 4-禁用/作废
            switch (type) {
                case '保存':
                    // this.ModelBill.Status = 0 //
                    if (this.billID !== 0) {
                        //修改
                        this.ModelBill.ModifiedOn = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                        this.ModelBill.ModifiedBy = this.USER.AccountID
                    }
                    break

                case '提交':
                    this.ModelBill.Status = 1
                    this.ModelBill.ModifiedOn = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                    this.ModelBill.ModifiedBy = this.USER.AccountID
                    break

                case '撤销':
                    this.ModelBill.Status = 0
                    break

                case '审核':
                    this.ModelBill.Status = 2
                    this.ModelBill.CheckedOn = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                    this.ModelBill.CheckedBy = this.USER.AccountID
                    break

                case '反审':
                    this.ModelBill.Status = 3
                    this.ModelBill.CheckedOn = ''
                    this.ModelBill.CheckedBy = ''
                    break

                case '启用':
                    this.ModelBill.Status = 3
                    this.ModelBill.DisabledOn = ''
                    this.ModelBill.DisabledBy = ''
                    break

                case '禁用':
                    this.ModelBill.Status = 4
                    this.ModelBill.DisabledOn = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                    this.ModelBill.DisabledBy = this.USER.AccountID
                    break
            }
            this.$BizApi.SaveModel(this.entity, this.ModelBill, (resp) => {
                this.billID = resp.Data.ID
                // this.$Util.MsgBox(type + '成功！', 1)
                this.$Util.MsgBox(type + '成功！', 1)
                this.refreshData()
            })
        }
    }
}
</script>

<style lang="scss">
.el-tabs__header {
    margin-bottom: 5px;
}
</style>
