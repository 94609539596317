<template>
    <app-container>
        <el-row>
            <el-col :span="4">
                <el-input id="input" type="text" v-model="filterStr" placeholder="关键词模糊搜索" @change="onRefresh" clearable />
            </el-col>
            <el-col :span="5">
                <el-button-group>
                    <el-button type="primary" @click="onRefresh" icon="el-icon-search">查询</el-button>
                    <el-button type="primary" @click="onCreate" icon="el-icon-circle-plus-outline">新增</el-button>
                </el-button-group>
            </el-col>
        </el-row>
        <el-table :data="listData.Items" ref="table" border stripe v-loading="loading" :height="tableHeight" style="width: 100%;" >
            <!--显示的字段-->
            <div v-for="(item, index) in FieldList" :key="index">
                <el-table-column v-if="item.type == 'bool'" :prop="item.prop" :label="item.label" :width="item.width" :formatter="formatBoolean" show-overflow-tooltip/>
                <el-table-column v-else-if="item.type == 'image'" :prop="item.prop" :label="item.label" :width="item.width" show-overflow-tooltip>
                    <template :width="item.width" slot-scope="scope">
                        <img style="width:60px;height:60px;border:none;" :src="scope.row[item.prop]">
                    </template>
                </el-table-column>
                <el-table-column :fixed="index == 0 ? true : item.fixed" v-else :prop="item.prop" :label="item.label" :width="item.width" show-overflow-tooltip/>
            </div>
            <el-table-column v-if="Operation.length > 0" fixed="right" label="操作" :width="100">
                <template slot-scope="scope">
                    <div v-for="(item,index) in Operation" :key="index">
                        <el-button v-if="item == 'delete'" type="text" size="small" @click="onDelete(scope.row)">删除</el-button>
                        <el-button v-if="item == 'disable'" type="text" size="small" @click="onDisable(scope.row)">禁用</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="currentSize"
            :total="listData.Total"
            :page-sizes="[20, 50, 100, 200, 500]"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>

        <!--新增-->
        <el-dialog :visible.sync="addVisible">
            <el-form :model="billModel" :rules="rules" ref="ruleForm" label-position="right" label-width="100px">
                <el-form-item label="文件名称" prop="Name">
                    <el-input v-model="billModel.Name" />
                </el-form-item>
                <el-form-item label="文件上传">
                    <el-upload
                        drag
                        ref="upload"
                        action=""
                        :auto-upload="false"
                        :on-exceed="handleExceed"
                        :on-change="onSuccessUpload"
                        :file-list="fileList"
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div style="height: 30px"/>
            <div>
                <el-button type="primary" plain @click="onDyFormSave">确认保存</el-button>
                <el-button type="success" plain @click="onDyFormCancel">取消保存</el-button>
            </div>
        </el-dialog>
    </app-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'document-list',
    components: {},
    computed: {
        ...mapGetters(['tag']),
        tableHeight() {
            return this.winHeight - 170 + 'px'
        }
    },
    watch: {
        listData() {
            this.doLayout()
        }
    },
    props:{
        //操作
        Operation:{
            type: Array,
            default: () => ['delete', 'disable']
        },
    },
    data() {
        return {
            winHeight: 0,
            loading: true,
            filterStr: '', //快捷查询的文本框
            currentPage: 1,
            currentSize: 20,
            listData: [{Total: 0,Items: []}],

            Entity: 'Document',
            FieldList: [
                { label: '文件编码', prop: 'Number' },
                { label: '文件名称', prop: 'Name' },
                { label: '是否禁用', prop: 'Disabled', type: 'bool' },
            ],
            DynamicForm: [
                {
                    label: '名称',
                    value: 'Name',
                    type: 'text',
                    placeholder: '',
                    readonly: false,
                    rules: []
                },
                {
                    label: '文件地址',
                    value: 'DocumentUrl',
                    type: 'upload',
                    col: 24,
                    placeholder: '',
                    rules: [],
                    readonly: false
                },
            ],
            rules: {
                Name: [
                    { required: true, message: '请输入文件名称', trigger: 'blur' },
                ],
            },

            addVisible: false,
            fileList: [],
            billModel:{ ID: 0, Name: '' },
        }
    },
    mounted() {
        //获取窗体的高度
        this.winHeight = window.innerHeight
        let that = this
        window.onresize = () => {
            return (() => {
                that.winHeight = window.innerHeight
            })()
        }

        this.onRefresh()
    },
    activated() {
        this.doLayout()
    },
    methods: {
        // 上传选择图片
        onSuccessUpload(file, fileList){
            this.fileList = fileList.slice(-3);
        },

        handleExceed() {
            this.$Util.MsgBox(`只能选择上传 1 个文件！`);
        },

        onDyFormSave(){
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.$API.Save(this.Entity, this.billModel, resp => {
                        this.$Util.MsgBox(resp.Message, 1)

                        this.addVisible = false

                        if(this.fileList.length > 0)
                            this.onUploadFile(resp.Data.ID)
                        else
                            this.onRefresh()
                    })
                } else {
                    return false;
                }
            })
        },

        async onUploadFile(id){
            if(this.fileList.length == 0){
                this.$Util.MsgBox('请先选择文件！')
                return
            }

            this.$refs.upload.submit();

            let formData = new FormData()
            formData.append(this.Entity + 'ID', id)
            formData.append('GroupID', '0')

            for (const pic of this.fileList){
                formData.append('File', pic.raw)
            }

            await this.$API.SyncPostService("File/Upload" + this.Entity + "/PostForm", formData)

            this.onRefresh()
        },

        onDyFormCancel(){
            this.fileList = []
            this.addVisible = false
        },

        doLayout() {
            this.$nextTick(() => {
                this.$refs.table.doLayout()
            })
        },
        formatBoolean: function (row, column, cellValue) {
            let ret = ''
            if (cellValue) {
                ret = "是"
            } else {
                ret = "否"
            }
            return ret;
        },
        handleSizeChange(val) {
            this.currentSize = val
            this.onRefresh()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.onRefresh()
        },
        // 查询按钮 - 获取接口数据
        onRefresh() {
            let params = {
                Page: this.currentPage,
                Size: this.currentSize,
                Filter: "",
                Keyword:  this.filterStr,
                Sort: "CreatedOn desc"
            }
            this.loading = true
            this.$API.GetList(this.Entity, params, resp => {
                if(resp.Status === 1){
                    this.listData = resp.Data
                    this.loading = false
                }
            })
        },

        //新增按钮
        onCreate() {
            this.$API.Create(this.Entity, resp => {
                this.billModel = resp.Data
                this.addVisible = true
            })
        },

        //删除
        onDelete(data){
            this.$confirm(this.$Util.IsNull(data.Number) ? '是否删除' : '是否删除' + data.Number, '提示' , {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$API.Remove(this.Entity, data.ID, () => {
                    this.onRefresh()
                })
            })
        },

        //禁用
        onDisable(data){
            this.$confirm(this.$Util.IsNull(data.Number) ? '是否禁用' : '是否禁用' + data.Name, '提示' , {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$API.Disable(this.Entity, data.ID, () => {
                    this.onRefresh()
                })
            })
        }
    }
}
</script>
