<template>
    <div class="bsy-upload">
        <el-upload
            action=""
            :limit="1"
            :auto-upload="false"
            :disabled="disabled"
            :file-list="DataValue"
            list-type="picture-card"
            :on-preview="onPreviewImg"
            :on-remove="onRemove"
            :on-exceed="onExceed"
            :before-upload="onBeforeUpload"
            :on-change="onSuccessChange"
            accept=".jpg, .jpeg, .png, .JPG, .JPEG"
        >
            <i class="el-icon-plus"></i>
        </el-upload>
        <span style="color: red;font-size: small">{{pictureTips}}</span>

        <el-dialog :visible.sync="imageDialog">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>
<script>

    export default {
        components: {  },
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            pictureTips: {
                type: String,
                default: "*请保持图片尺寸比例（如：800 x 400）否则将影响显示效果"
            },
            DataValue: null
        },
        data () {
            return {
                imageDialog: false,
                dialogImageUrl: '',
                imageList: [],
            }
        },
        mounted () {

        },
        methods: {
            // 上传图片限制规格大小
            onBeforeUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2
                if(!isLt2M) {
                    this.$Util.MsgBox('上传文件大小不能超过 2MB', -1)
                }
                return isLt2M
            },

            onExceed() {
                this.$Util.MsgBox(`只能选择上传 1 张图片`);
            },
            // 上传轮播图
            onSuccessChange(res, file){
                // console.log('res', res)
                // console.log('file', file)

                this.imageList = file
                this.$emit('onSuccessChange', file)
            },

            // 删除图片
            onRemove(file, fileList) {
                // console.log('file', file)
                // console.log('fileList', fileList)

                // this.ImageUrl = [{url: ''}]
                this.$emit('onSuccessChange', [])
            },

            onPreviewImg(file) {
                this.imageDialog = true;
                this.dialogImageUrl = file.url;
            },
        },
    }
</script>
<style lang="scss" scoped>
    .bsy-upload{

    }
</style>
