<template>
    <div>
        <el-card class="bsy-bill-card" v-if="currentClientID !== 'Admin'" >
            <div slot="header">
                <span>用户信息</span>
            </div>
            <el-form label-position="right" label-width="100px" :disabled="disabled" :model="userModel" :rules="userRules" >
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="账号" prop="Number">
                                <el-input v-model="userModel.Number" placeholder="请输入正确的手机号码"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="姓名">
                                <el-input v-model="userModel.Name" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="职务">
                                <el-select v-model="userModel.PositionID" placeholder="请选择企业职务" style="width: 100%">
                                    <div v-for="(item,index) in EnterprisePositionList" :key="index">
                                        <el-option :label="item.Name" :value="item.ID"/>
                                    </div>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="邮箱">
                                <el-input v-model="userModel.Email" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                        <el-row>
<!--                            <el-col :span="6">-->
<!--                                <el-form-item label="管理员">-->
<!--                                    <el-select v-model="userModel.IsAdmin" disabled style="width: 100%">-->
<!--                                        <el-option label="是" :value="true"/>-->
<!--                                        <el-option label="否" :value="false"/>-->
<!--                                    </el-select>-->
<!--                                </el-form-item>-->
<!--                            </el-col>-->
                            <el-col :span="6">
                                <el-form-item label="联系电话">
                                    <el-input v-model="userModel.Phone" />
                                </el-form-item>
                            </el-col>
                        </el-row>
            </el-form>
        </el-card>
        <el-card class="bsy-bill-card">
            <div slot="header">
                <span>企业信息</span>
            </div>
            <el-form :model="billModel" :rules="rules" ref="ruleForm" label-position="right" label-width="100px" :disabled="disabled">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="企业编码">
                            <el-input readonly v-model="billModel.Number" placeholder="保存后自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="企业名称" prop="Name">
                            <el-input v-model="billModel.Name" placeholder="请输入企业名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="企业类型" prop="EnterpriseTypeID">
                            <el-select v-model="billModel.EnterpriseTypeID" placeholder="请选择企业类型" style="width: 100%">
                                <div v-for="(item,index) in EnterpriseTypeList" :key="index">
                                    <el-option :label="item.Name" :value="item.ID"/>
                                </div>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="企业电话" prop="Phone">
                            <el-input v-model="billModel.Phone" placeholder="请输入企业电话"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="营业执照" prop="License">
                            <el-input v-model="billModel.License" placeholder="请输入营业执照"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="省市区域">
                            <el-cascader style="width: 100%" :options="areaOptions" v-model="areaSelVal" @change="onAreaChange" />
                        </el-form-item>
                    </el-col>
<!--                        <el-col :span="6">-->
<!--                            <el-form-item label="所在省份" prop="Province">-->
<!--                                <el-input v-model="billModel.Province" placeholder="请输入所在省份"></el-input>-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
<!--                        <el-col :span="6">-->
<!--                            <el-form-item label="所在城市" prop="District">-->
<!--                                <el-input v-model="billModel.City" placeholder="请输入所在城市"></el-input>-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
<!--                        <el-col :span="6">-->
<!--                            <el-form-item label="所在地区" prop="District">-->
<!--                                <el-input v-model="billModel.District" placeholder="请输入所在地区"></el-input>-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
                    <el-col :span="12">
                        <el-form-item label="具体地址" prop="Address">
                            <el-input v-model="billModel.Address" placeholder="请输入具体地址"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!--                <el-row>-->
                <!--                    -->
                <!--                    <el-col :span="6">-->
                <!--                        <el-form-item label="区域代码" prop="RegionCode">-->
                <!--                            <el-input v-model="billModel.RegionCode" placeholder="区域代码"></el-input>-->
                <!--                        </el-form-item>-->
                <!--                    </el-col>-->
                <!--                </el-row>-->
            </el-form>
        </el-card>
        <el-card class="bsy-bill-card">
                <div slot="header">
                    <span>其他信息</span>
                </div>
                <el-form :model="billModel" label-position="right" label-width="100px" :disabled="disabled">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="创建时间">
                                <el-input readonly v-model="billModel.CreatedOn" placeholder="保存后自动生成"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="创建人">
                                <el-input readonly v-model="billModel.CreatedBy" placeholder="保存后自动生成"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="修改时间">
                                <el-input readonly v-model="billModel.ModifiedOn" placeholder="保存后自动生成"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="修改人">
                                <el-input readonly v-model="billModel.ModifiedBy" placeholder="保存后自动生成"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>
        <bsy-buttons ref="BSYButtons" :buttons="buttons" :parentVue="thisVue"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BsyButtons from "../../components/bsy-ui/bsy-buttons";
import { CodeToText, regionData } from 'element-china-area-data'

export default {
    name: 'enterprise-detail',
    components: {BsyButtons},
    computed: {
        ...mapGetters(['USER', 'collapsed']),
    },
    data() {
        var validatorPhone = function (rule, value, callback) {
            if (value === '') {
                callback(new Error('手机号不能为空'))
            } else if(!(/^1[3456789]\d{9}$/.test(value))){
                callback(new Error('手机号格式错误'))
            } else {
                callback()
            }
        }
        return {
            thisVue: this,
            buttons: [],
            disabled: false,
            currentClientID: '',

            // 地区选择
            areaSelVal: [],
            areaOptions: regionData,

            EnterpriseTypeList: [],
            EnterprisePositionList: [],

            billID: 0,
            billModel: {},
            userModel: { Number: '' },
            userRules:{
                Number: [
                    { required: true, validator: validatorPhone, trigger: 'blur' }
                ]
            },
            rules: {
                Name: [
                    { required: true, message: '请输入企业名称', trigger: 'blur' },
                ],
                License: [
                    { required: true, message: '请输入营业执照编号', trigger: 'blur' },
                ],
                Phone: [
                    { required: true, message: '请输入企业电话', trigger: 'blur' },
                ],
                Address: [
                    { required: true, message: '请输入具体地址', trigger: 'blur' },
                ],
            },

        }
    },
    mounted() {
        this.onInit()

        this.currentClientID = this.$Util.GetStore("CurrentClientID")
        switch (this.currentClientID){
            //管理员
            case "Admin":
                this.billID = Number(this.$route.query.id)
                this.onGetModel()
                this.disabled = true
                break

            //社会组织
            case "Community":
                //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                this.buttons = [{ label: '保存', event: 'onSave', icon: 'el-icon-check', disabled: false }]

                this.billID = Number(this.$route.query.id)
                if (this.billID === 0) {
                    this.onCreate()
                    // this.onCreateUser()
                }else{
                    this.onGetModel()
                }
                break

            //企业机构
            case "Enterprise":
                //A：待提交、B：已提交、C；已受理、D：已驳回、E：已告知、F：已结案、G：已关闭
                this.buttons = [{ label: '保存', event: 'onSave', icon: 'el-icon-check', disabled: false }]

                // 获取缓存的用户信息
                this.userModel = this.$Util.GetStore("UserInfo")
                this.billID = this.userModel.EnterpriseID

                if (this.billID === 0) {
                    this.onCreate()
                }else{
                    this.onGetModel()
                }
                break

            //调解机构
            case "Mediator":

                break
        }
    },
    methods: {
        onInit(){
            // 企业类型
            this.$API.GetList('EnterpriseType', {}, (resp) => {
                this.EnterpriseTypeList = resp.Data.Items
            })

            // 企业职务
            this.$API.GetList('EnterprisePosition', {}, (resp) => {
                this.EnterprisePositionList = resp.Data.Items
            })
        },
        onCreateUser(){
            this.$API.Create("EnterpriseUser", (resp) => {
                this.userModel = resp.Data
            })
        },
        onCreate(){
            this.$API.Create("Enterprise", (resp) => {
                this.billModel = resp.Data

                this.onInitArea(this.billModel.RegionCode)
            })
        },
        onGetModel() {
            this.$API.GetModel("Enterprise", this.billID, (resp) => {
                this.billModel = resp.Data
                this.onInitArea(this.billModel.RegionCode)

                if(resp.Data.AdminUserID === 0)
                    this.onCreateUser()
                else
                    this.onGetModelUser(resp.Data.AdminUserID)
            })
        },
        onGetModelUser(id) {
            this.$API.GetModel("EnterpriseUser", id, (resp) => {
                this.userModel = resp.Data
            })
        },

        //初始化地区组件需要的值
        onInitArea(regionCode){
            if(this.$Util.IsNull(regionCode.trim())){
                regionCode = "440605"
            }
            let province = regionCode.substr(0, 2)
            let city = regionCode.substr(2, 2)
            this.areaSelVal = [ province + "0000", province + city + "00", regionCode]

            this.onSetAreaToText()
        },

        // 地区组件选择值改变事件
        onAreaChange(arr) {
            this.areaSelVal = arr

            this.onSetAreaToText()
        },

        // 地区代码转换中文省市区
        onSetAreaToText(){
            this.billModel.Province = CodeToText[this.areaSelVal[0]]
            this.billModel.City = CodeToText[this.areaSelVal[1]]
            this.billModel.District = CodeToText[this.areaSelVal[2]]
            this.billModel.RegionCode = this.areaSelVal[2]
        },

        // 保存
        onSave() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.currentClientID = this.$Util.GetStore("CurrentClientID")
                    switch (this.currentClientID){
                        //管理员
                        case "Admin":
                        case "Community":
                            // 保存企业资料
                            this.$API.Save("Enterprise", this.billModel, resp => {
                                this.billID = resp.Data.ID
                                this.billModel = resp.Data
                                this.userModel.EnterpriseID = this.billID
                                this.userModel.ClientID = this.$Config.api.clientID + 'Enterprise'

                                // 保存企业用户
                                this.$API.PostService('EnterpriseUser/SaveProfile', this.userModel, result => {
                                    this.$Util.MsgBox(result.Message, 1)
                                    this.userModel = result.Data
                                })
                            })
                            break

                        //企业机构
                        case "Enterprise":
                            // // 保存企业用户
                            // this.$API.PostService('EnterpriseUser/UpdateProfile', this.userModel, result => {
                            //     this.userModel = result.Data
                            //     this.$Util.SetStore("UserInfo", result.Data)
                            //
                            //     // 保存企业资料
                            //     this.$API.Save("Enterprise", this.billModel, resp => {
                            //         this.$Util.MsgBox(resp.Message, 1)
                            //
                            //         this.billModel = resp.Data
                            //     })
                            // })
                            // 保存企业资料
                            this.$API.Save("Enterprise", this.billModel, resp => {
                                this.billID = resp.Data.ID
                                this.billModel = resp.Data
                                this.userModel.EnterpriseID = this.billID
                                this.userModel.ClientID = this.$Config.api.clientID + 'Enterprise'

                                // 保存企业用户
                                this.$API.PostService('EnterpriseUser/UpdateProfile', this.userModel, result => {
                                    this.userModel = result.Data
                                    this.$Util.SetStore("UserInfo", result.Data)

                                    this.$Util.MsgBox(resp.Message, 1)
                                })
                            })
                            break
                    }
                } else {
                    return false;
                }
            });
        }
    }
}
</script>

<style scoped lang="scss">
.bsy-bill-card{
    margin-bottom: 5px;
}
</style>
